import Button from '../class/button';

export default class ButtonText extends Phaser.GameObjects.Container {
  constructor(scene, x, y, texture, actionOnClick = () => {}, overFrame, outFrame, downFrame, textOptions = {
    text: 'BUTTON',
    color: '#00e7ff',
    size: 35}) {
    super(scene)
    scene.add.existing(this)

    const button = new Button(this.scene, x,y, texture, actionOnClick, overFrame, outFrame, downFrame).setOrigin(0.5, 0.5)


    const buttonText = this.scene.add.text(x, y, textOptions.text, {
      fontFamily: 'electrolize',
      fontSize: textOptions.size,
      fill: textOptions.color
    }).setOrigin(0.5, 0.5);
    this.add(button);
    this.add(buttonText);



  }
}
