import OctaBalance from './OctaBalance';
import Button from '../class/button';
import store from '@/store';
import eventBus from '@/plugins/event-bus';
import NumberFormat from 'number-format.js';

export default class GameMenuTop extends Phaser.GameObjects.Container {
  constructor(scene, x, y) {
    super(scene, x, y);
    scene.add.existing(this);
    let _self = this;

    /*
    this.avBgTop = scene.add.image(120, 90, 'avBgTop').setOrigin(0.5, 0.5).setScale(0.6).setInteractive();
    this.avBgTop.on('pointerdown', async function () {
      eventBus.emit('modal:settings');
    }, this);
     */

    this.avBgTop = new Button(scene, 120, 90, 'btnAv', () => {
      eventBus.emit('modal:settings');
    }, 'hover', 'default', 'hover').setScale(0.6);


    this.avatar = scene.add.image(120, 82, 'avatars', 'male')
      .setOrigin(0.5, 0.5).setScale(0.44);

    let account = store.getters['account/account'];
    if (account.userId) {
      store.dispatch('account/getPlayerInfo', account.userId);
    }

    scene.game.config.player = store.getters['account/player'];

    eventBus.on('update:balance', async (player) => {
      scene.game.config.player = player;
      this.setBalanceSth(player.sth);
      this.setBalanceChips(player.chips.real);
    });

    this.balanceChips = new OctaBalance(scene, 550, 70, {
      icon: 'goldCoin',
      scale: 0.70,
      x: -225,
      balance: this.numberFormat(scene.game.config.player.chips.real, '# ##0.'),
      type: 'chips',
      color: '#fbd561'
    });
    this.balanceSth = new OctaBalance(scene, scene.game.config.width - 550, 70, {
      icon: 'chipSth',
      scale: 0.40,
      x: 225,
      balance: this.numberFormat(scene.game.config.player.sth, '# ##0.00'),
      type: 'sth',
      color: '#ffffff'
    });
    this.playerName = scene.add.text(120, 180, scene.game.config.player.name, {
      fontFamily: 'electrolize',
      fontSize: 21,
    }).setOrigin(0.5, 0.5);

    eventBus.on('player:update', function (player) {
      _self.playerName.text = player.name;
      scene.game.config.sth = player.sth;
      _self.setBalanceSth(player.sth);
      _self.setBalanceChips(player.chips.real);
      _self.setSex(player.sex);
    });

    eventBus.on('balance:sth', function (balance) {
      scene.game.config.sth = balance;
      _self.setBalanceSth(balance);
    });

    this.add([
      scene.add.image(scene.game.config.width / 2, -60, 'menuTop').setOrigin(0.5, 0),
      this.avBgTop,
      this.avatar,
      this.playerName,
      scene.add.text(scene.game.config.width / 2, 70, "TEXAS HOLD'EM POKER", {
        fontFamily: 'electrolize',
        fontSize: 32
      }).setOrigin(0.5, 0.5),
      this.balanceChips,
      this.balanceSth,
      new Button(scene, scene.game.config.width - 120, 80, 'btnPrev', () => {
        this.animTopMenu.resume();
        scene.scene.switch('PreScene');
        if (scene.scene.key !== 'ChipsScene') {
          scene.menuBot.tweenPanel.resume();
          scene.menuBot.tweenBtnStart.resume();
        }
      }, 'btn_prev_hover.png', 'btn_prev.png', 'btn_prev.png').setOrigin(0.5, 0.5),
    ]);

    this.animTopMenu = scene.tweens.add({
      targets: [this],
      y: '0',
      ease: 'Sine.easeInOut',
      duration: 900,
    });

  }

  setBalanceSth(amount) {
    this.balanceSth.setBalance(amount)
  }

  setBalanceChips(amount) {
    this.balanceChips.setBalance(amount)
  }

  setSex(sex) {
    this.avatar.setFrame(sex);
  }

  numberFormat(numValue, mask = '# ##0.00') {
    return NumberFormat(mask, numValue)
  }


  resume() {

  }
}
