export default class HiddenCards extends Phaser.GameObjects.Container {
  constructor(scene, x, y) {
    super(scene, x, y);
    scene.add.existing(this);
    this.setVisible(false);

    this.add([
      scene.add.image(0, 0, 'hiddenCards').setOrigin(0.5, 0.5)//.setScale(0.55)
    ]);
  }

  show() {
    this.setVisible(true);
  }

  hide() {
    this.setVisible(false);
  }

}
