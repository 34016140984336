import Button from "../class/button";
import store from '@/store';
import eventBus from '@/plugins/event-bus';

export default class PopupExitGame extends Phaser.GameObjects.Container {
  constructor(scene, x, y) {
    super(scene, x, y);
    scene.add.existing(this);
    this.setVisible(false);

    this.add([
      scene.add.sprite(0, 0, 'overlay').setOrigin(0.5, 0.5).setInteractive(),
      scene.add.image(0, 0, 'formExitBg').setOrigin(0.5, 0.5),
      scene.add.text(0, -80, scene.game.config[scene.game.config.locale]['GAME']['Q_EXIT'], {
        fontFamily: 'electrolize',
        fontSize: 48,
        fill: '#2c799f'
      }).setOrigin(0.5, 0.5),
      new Button(scene, 180, 203, 'btnYesNo', async () => {
        this.setVisible(false);
        await store.dispatch('games/tableLogout');
        eventBus.emit('chat:hide');
        //scene.scene.get('GameScene');
        scene.scene.sleep('GameScene');
        await store.dispatch('games/tableUpdate', {
          tid: null,
          options: {
            idx: -1
          }
        });
        scene.scene.switch('GameMenu');
      }, 'btnYesHover.png', 'btnYes.png', 'btnYesHover.png').setOrigin(0.5, 0.5),
      new Button(scene, -174, 203, 'btnYesNo', () => {
        this.setVisible(false);
      }, 'btnNoHover.png', 'btnNo.png', 'btnNoHover.png').setOrigin(0.5, 0.5)
    ]);
  }


}
