import eventBus from '@/plugins/event-bus';

export default class Overlay extends Phaser.GameObjects.Sprite {
  constructor(scene) {
    super(scene, scene.game.config.width / 2, scene.game.config.height / 2, 'overlay');
    scene.add.existing(this);
    this.setVisible(false);
    this.setInteractive();

    eventBus.on('overlay:hide', async () => {
      this.setVisible(false);
    });

    eventBus.on('overlay:show', async () => {
      this.setVisible(true);
    });
  }

  show() {
    this.setVisible(true);
  }

  hide() {
    this.setVisible(false);
  }
}
