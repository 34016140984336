import {Scene} from 'phaser'
import BtnChips from './class/BtnChips';
import GameMenuTop from './menu/GameMenuTop';
import store from '@/store';
import eventBus from '@/plugins/event-bus';
import Overlay from "./Modules/Overlay";
import Button from './class/button';

export default class ChipsScene extends Scene {
  constructor() {
    super({key: 'ChipsScene'})
  }

  async create() {
    console.log('ChipsScene');
    this.game.config.player =  await store.dispatch('account/getPlayerInfo');
    this.game.config.sth = await store.dispatch('account/getSthBalance');
    this.add.image(this.game.config.width / 2, this.game.config.height / 2, 'bgStars').setOrigin(0.5, 0.5);
    this.add.image(this.game.config.width - 255, this.game.config.height / 2, 'backEffect').setOrigin(0, 0.5);
    this.add.image(0, this.game.config.height / 2, 'backEffect').setOrigin(0, 0.5).setFlipX(true);

    let chipScale = 0.8;

    this.chips2500 = new BtnChips(this, 550, 390, 'chips', () => {
      eventBus.emit('modal:chips', 2500);
    }, 'hover', '2500').setScale(chipScale);
    this.chips5K = new BtnChips(this, 550 + 400, 390, 'chips', () => {
      eventBus.emit('modal:chips', 5000);
    }, 'hover', '5000').setScale(chipScale);
    this.chips10K = new BtnChips(this, 550 + 400 * 2, 390, 'chips', () => {
      eventBus.emit('modal:chips', 10000);
    }, 'hover', '10000').setScale(chipScale);

    this.chips25K = new BtnChips(this, 350, 420 + 300, 'chips', () => {
      eventBus.emit('modal:chips', 25000);
    }, 'hover', '25000').setScale(chipScale);
    this.chips50K = new BtnChips(this, 350 + 400, 420 + 300, 'chips', () => {
      eventBus.emit('modal:chips', 50000);
    }, 'hover', '50000').setScale(chipScale);
    this.chips100K = new BtnChips(this, 350 + 400 * 2, 420 + 300, 'chips', () => {
      eventBus.emit('modal:chips', 100000);
    }, 'hover', '100000').setScale(chipScale);
    this.chips250K = new BtnChips(this, 350 + 400 * 3, 420 + 300, 'chips', () => {
      eventBus.emit('modal:chips', 250000);
    }, 'hover', '250000').setScale(chipScale);


    this.menuTop = new GameMenuTop(this, 0, -456);

    this.add.image(this.game.config.width / 2, this.game.config.height - 350, 'menuTop').setOrigin(0.5, 0).setFlipY(true);

    this.add.text(this.game.config.width / 2, this.game.config.height - 60, '1 STH = 1 CRYPTO ' + this.game.config[this.game.config.locale]['GAME']['CHIP'], {
      fontFamily: 'electrolize',
      fontSize: 32
    }).setOrigin(0.5, 0.5);


    this.btnSellChips = new Button(this, this.game.config.width - 130, this.game.config.height - 75, 'btnNormal', () => {
      eventBus.emit('modal:sellchips');
    }, 'btn_press.png', 'btn_normal.png', 'btn_press.png').setScale(0.7);
    this.add.text(this.game.config.width - 130, this.game.config.height - 75, this.game.config[this.game.config.locale]['GAME']['SELL_CHIPS'], {
      fontFamily: 'electrolize',
      fontSize: 18
    }).setOrigin(0.5, 0.5);

    this.add.text(300, this.game.config.height - 60, this.game.config[this.game.config.locale]['GAME']['BUY_CHIPS'], {
      fontFamily: 'electrolize',
      fontSize: 32
    }).setOrigin(0, 0.5);

    this.overlay = new Overlay(this).setInteractive();

  }

  update() {

  }
}
