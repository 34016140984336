import CommunityCards from './CommunityCards';
import Bank from './Bank';

export default class Table extends Phaser.GameObjects.Container {
  constructor(scene, x, y, color = 'blue') {
    super(scene, x, y);
    scene.add.existing(this);

    this.currentRound = scene.add.text(0, 60, 'WAIT', {
      fontFamily: 'electrolize',
      fontSize: 32
    }).setOrigin(0.5, 0.5).setAlpha(0.3);

    this.communityCards = new CommunityCards(scene, x, y);
    this.Bank = new Bank(scene, 0, 0);

    this.BuyIn = scene.add.text(0, -120, 'BUY-IN ' + '0/0', {
      fontFamily: 'electrolize',
      fontSize: 32
    }).setOrigin(0.5, 0.5).setAlpha(0.3).setName('buyIn');


    this.gameCounter = scene.add.text(100, -398, '', {
      fontFamily: 'electrolize',
      fontSize: 26,
      color: 'red',
    }).setOrigin(0.5, 0.5).setAlpha(0.9);

    this.deckHash = scene.add.text(0, 90, '', {
      fontFamily: 'electrolize',
      fontSize: 18
    }).setOrigin(0.5, 0.5).setAlpha(0.3);

    this.add([
      scene.add.image(0, -20, 'tb1Purple').setOrigin(0.5, 0.5).setScale(1),
      scene.add.image(0, -50, 'logoTable',).setOrigin(0.5, 0.5),
      scene.add.image(0, -322, 'deck').setOrigin(0.5, 0.5),
      this.gameCounter,
      this.BuyIn,
      this.deckHash,
      this.currentRound,
      this.communityCards,
      this.Bank,
    ]);
  }

  setDeckHash(hash) {
    this.deckHash.text = hash;
  }

  setRoundName(name) {
    this.currentRound.text = name;
  }

  setBank(amount) {
    this.Bank.setBank(amount);
  }

  setCommunityCards(cards = []) {
    this.communityCards.setCards(cards)
  }

  setBuyIn(min, max) {
    this.BuyIn.text = 'BUY-IN ' + this.amountFormat(min) + '/' + this.amountFormat(max)
  }

  setGameCounter(counter) {
    this.gameCounter.text = '#'+counter;
  }


  amountFormat(amount) {
    let result = amount;
    if (amount >= 1000) {
      result = (amount / 1000) + 'K'
    }

    if (amount >= 1000000) {
      result = (amount / 1000000) + 'M'
    }

    return result;
  }

}
