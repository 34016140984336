import Button from '../class/button';
import Settings from "../panels/Settings";
import eventBus from '@/plugins/event-bus';
//import FreeChips from './FreeChips';
import store from '@/store';

export default class GameMenuBot extends Phaser.GameObjects.Container {
  constructor(scene, x, y) {
    super(scene, x, y);
    scene.add.existing(this);

    this.btnStartGame = new Button(scene, 0, 600, 'btnStart', async () => {
      await store.dispatch('games/getLobby');
      this.tweenBtnStart.resume();
      this.tweenPanel.resume();
      scene.menuTop.animTopMenu.resume();
      scene.scene.sleep('GameMenu');
      scene.scene.switch('LobbyScene');
      scene.scene.get('PreScene').soundDoor.play();
    }, 'btn_start_hover.png', 'btn_start.png', 'btn_start.png');


    this.settings = new Settings(scene, x, y / 2);
    this.btnSetting = new Button(scene, 800, 100, 'settingsBot', () => {
      this.settings.show();
    }, 'SettingBotHover.png', 'SettingBot.png', 'SettingBot.png');

    this.btnWallet = new Button(scene, 550, 100, 'btnWallet', () => {
      eventBus.emit('modal:wallet');
    }, 'gold', 'blue', 'gold');

    //this.formFreeChips = new FreeChips(scene, scene.game.config.width / 2, scene.game.config.height / 2);

    /*
    this.btnFreeChips = new Button(scene, -350, 100, 'btnTrophy', () => {
      scene.formFreeChips.show();
    }, 'trophy_hover.png', 'trophy.png', 'trophy_hover.png');
     */

    this.btnRealChips = new Button(scene, -850, 100, 'btnBuy', () => {
      scene.scene.switch('ChipsScene');
    }, 'hover', 'default', 'hover').setScale(1.1);

    this.sellChips = new Button(scene, -600, 100, 'btnSell', () => {
      eventBus.emit('modal:sellchips');
    }, 'hover', 'default', 'hover').setScale(0.88);



    this.add([
      this.btnStartGame,
      scene.add.image(0, 255, 'menuBot').setOrigin(0.5, 1),
      scene.add.text(800, 200, scene.game.config[scene.game.config.locale]['APP']['SETTINGS'].toUpperCase(), {
        fontFamily: 'electrolize',
        fontSize: 28
      }).setOrigin(0.5, 0.5),
      scene.add.text(550, 200, scene.game.config[scene.game.config.locale].WALLET.WALLET, {
        fontFamily: 'electrolize',
        fontSize: 28
      }).setOrigin(0.5, 0.5),
      scene.add.text(-850, 200, scene.game.config[scene.game.config.locale]['GAME']['BUY_CHIPS'], {
        fontFamily: 'electrolize',
        fontSize: 28
      }).setOrigin(0.5, 0.5),
      scene.add.text(-600, 200, scene.game.config[scene.game.config.locale]['GAME']['SELL_CHIPS'], {
        fontFamily: 'electrolize',
        fontSize: 28
      }).setOrigin(0.5, 0.5),
      /*
      scene.add.text(-350, 200, 'FREE CHIPS', {
        fontFamily: 'electrolize',
        fontSize: 28
      }).setOrigin(0.5, 0.5),
       */
      this.btnSetting,
      this.btnWallet,
       //this.btnFreeChips,
      this.btnRealChips,
      this.sellChips,
    ]);

    this.tweenPanel = scene.tweens.add({
      targets: [this],
      y: '-=255',
      ease: 'Sine.easeInOut',
      duration: 400,
    });

    this.tweenBtnStart = scene.tweens.add({
      targets: this.btnStartGame,
      alpha: {from: 0, to: 1},
      y: '-=540',
      ease: 'Sine',
      duration: 1000,
      onStart: async function () {

      },
      onComplete: async function () {
        //scene.soundDoor.resume();
        //scene.soundDoor.setSeek(0);
      },
      onActive: function () {

      },
    });

    this.AnimSettings = scene.tweens.add({
      targets: [this.btnSetting],
      angle: 360,
      ease: 'Sine.easeInOut',
      duration: 800,
      yoyo: true
    });

  }
}
