import eventBus from '@/plugins/event-bus';

export default class Button extends Phaser.GameObjects.Sprite {
  onInputOver = () => {};
  onInputOut = () => {};
  onInputUp = () => {};

  constructor(scene, x, y, texture, actionOnClick = () => {}, overFrame, outFrame, downFrame) {
    super(scene, x, y, texture);
    scene.add.existing(this);

    const soundClick = scene.sound.add('soundClick', {
      mute: !scene.game.config.sound,
      volume: 1,
      rate: 1,
      detune: 0,
      seek: 0,
      loop: false,
      delay: 0
    });

    eventBus.on('set:sound', async function (data) {
      soundClick.setMute(!data);
    });

    this.setFrame(outFrame)
      .setInteractive()

      .on('pointerover', () => {
        this.onInputOver()
        this.setFrame(overFrame)
      })
      .on('pointerdown', () => {
        actionOnClick();
        soundClick.play();
        this.setFrame(downFrame);
      })
      .on('pointerup', () => {
        this.onInputUp()
        this.setFrame(overFrame)
      })
      .on('pointerout', () => {
        this.onInputOut()
        this.setFrame(outFrame)
      })
  }
}
