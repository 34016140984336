import {Scene} from 'phaser'
import GameMenuTop from '../menu/GameMenuTop';
import GameMenuBot from '../menu/GameMenuBot';
import store from '@/store';
import AccountSettings from "../menu/AccountSettings";
import Counter from "../class/Counter";
import FreeChips from '../menu/FreeChips';
import Overlay from '../Modules/Overlay';
import eventBus from '@/plugins/event-bus';
import CounterGames from "../class/CounterGames";

let galaxy;
let counter;

export default class GameMenu extends Scene {
  constructor() {
    super({key: 'GameMenu'});

  }

  preload() {

  }

  wake() {
    //console.log('wake')
    this.scene.run('GameMenu');
    this.scene.get('PreScene').soundDoor.play();
    const counter = store.getters['app/counter'];
    this.userCounter.setOnline(counter.clients);
  }

  async create() {
    this.scene.get('PreScene').soundDoor.play();
    let _self = this;
    //this.game.config.player =  store.getters['account/player'];
    this.game.config.player = await store.dispatch('account/getPlayerInfo');
    this.game.config.sth = await store.dispatch('account/getSthBalance');
    this.add.image(this.game.config.width / 2, this.game.config.height / 2, 'bgStars').setOrigin(0.5, 0.5);

    this.bonusTime = this.add.image(this.game.config.width / 2, this.game.config.height / 2 - 50, 'bonusTime')
      .setOrigin(0.5, 0.5)
      .setInteractive().setVisible(false); // бесплатные фишки

/*
    let tMenu = [];

    for (let i = 0; i < 1; i++) {
      tMenu.push(
        this.add.text(this.game.config.width / 2 - 500, -20, "Welcome " + this.game.config.player.name, {
          fontFamily: 'electrolize',
          fontSize: 48,
          color: '#91fdfe'
        }).setOrigin(0.5, 0.5)
      )
    }

    let animBlock = this.tweens.add({
      targets: tMenu[0],
      //x: 0,
      y: this.game.config.height / 2 - 100,
      scale: {from: 0, to: 1},
      ease: 'Sine.easeInOut',
      duration: 1500,
      //yoyo: true,
      //angle: 180,
      onComplete: function () {
        if (blockId !== 0) {
          tMenu[0].text = blockId;
        }
      },
      //paused: true,
    });



    let blockId = 0;
    eventBus.on('sth:block:new', async function (data) {
      //blockId = data.id;
      //animBlock.play(true);
    });

 */


    let textUpdateRelease = this.add.text(this.game.config.width / 2, this.game.config.height / 2, "", {
      fontFamily: 'electrolize',
      fontSize: 48,
      color: 'yellow'
    }).setOrigin(0.5, 0.5).setVisible(false).setInteractive().on('pointerdown', () => {
      eventBus.emit('url:open', "https://playpoker.pro");
    });
    eventBus.on('app:show:update', async function (data) {
      textUpdateRelease.text = this.game.config[this.game.config.locale]['GAME']['NEW_RELEASE'] + data;
      textUpdateRelease.setVisible(true);
    });

    const isNewRelease = await store.dispatch('app/getRelease');

    if (isNewRelease) {
      textUpdateRelease.text = this.game.config[this.game.config.locale]['GAME']['NEW_RELEASE'] + isNewRelease;
      textUpdateRelease.setVisible(true);
    }

    galaxy = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2 - 70, 'galaxy1').setScale(0.6);
    this.menuTop = new GameMenuTop(this, 0, -456);
    this.menuBot = new GameMenuBot(this, this.game.config.width / 2, this.game.config.height);

    this.AccountSettingsForm = new AccountSettings(this, this.game.config.width / 2, this.game.config.height / 2);
    this.formFreeChips = new FreeChips(this, this.game.config.width / 2, this.game.config.height / 2);
    this.bonusTime.on('pointerdown', async function () {
      this.formFreeChips.show();
    }, this);

/*
    this.add.image(100, this.game.config.height / 2 + 200, 'iconProfile').setOrigin(0.5, 0.5).setScale(0.7);
    this.online = this.add.text(130, this.game.config.height / 2 + 200, counter.clients + 1, {
      fontFamily: 'electrolize',
      fontSize: 48,
      color: '#91fdfe'
    }).setOrigin(0, 0.5);
    eventBus.on('app:counter:update', async function (data) {
      _self.online.text = data.clients + 1;
    });
 */

    this.userCounter = new Counter(this, 100, this.game.config.height / 2 + 200);
    this.gameCounter = new CounterGames(this, this.game.config.width - 60, this.game.config.height / 2 + 200);



    this.overlay = new Overlay(this);


    /*
    let lines = this.add.container(0,0, [
      this.add.line(
        220,
        300,
        0,
        0,
        180,
        200,
        0xaed7f1,
        0.5,
      ),
      this.add.line(
        400,
        400,
        0,
        0,
        180,
        0,
        0xaed7f1,
        0.5,
      )
    ]).setOrigin(0, 0)
     */

    this.sys.events.on('wake', this.wake, this);

    this.animGalaxy = this.tweens.add({
      targets: galaxy,
      //x: 0,
      //y: this.game.config.height / 2 - 100,
      scale: {from: 0, to: 0.6},
      ease: 'Sine.easeInOut',
      duration: 1500,
      //yoyo: true,
      //angle: 180,
      onComplete: function () {

      },
      //paused: true,
    });


  }

  update() {
    if (galaxy) {
      galaxy.angle += 0.08;
    }

  }
}
