export default class ButtonChips extends Phaser.GameObjects.Sprite {
  onInputOver = () => {
  };
  onInputOut = () => {
  };
  onInputUp = () => {
  };

  constructor(scene, x, y, texture, actionOnClick = () => {
  }, overFrame, outFrame) {
    super(scene, x, y, texture);
    this.hover = scene.add.image(x, y, 'chips', 'hover').setScale(0.8).setVisible(false);
    scene.add.existing(this);


    this.setFrame(outFrame)
      .setInteractive()
      .on('pointerover', () => {
        this.onInputOver();
        this.hover.setVisible(true);
        //this.setFrame(overFrame)
      })
      .on('pointerdown', () => {
        actionOnClick();
        //this.setFrame(overFrame)
        this.hover.setVisible(true);
      })
      .on('pointerup', () => {
        this.onInputUp();
        //this.setFrame(overFrame)
        this.hover.setVisible(true);
      })
      .on('pointerout', () => {
        this.onInputOut();
        //this.setFrame(outFrame);
        this.hover.setVisible(false);
      })
  }
}
