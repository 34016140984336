import Button from '../class/button';
import Settings from "../panels/Settings";
import eventBus from '@/plugins/event-bus';

export default class PreMenuBot extends Phaser.GameObjects.Container {
  constructor(scene, x, y) {
    super(scene, x, y);
    scene.add.existing(this);
    this.settings = new Settings(scene, x, y / 2);

    this.btnMessage = new Button(scene, -850, 100, 'message', () => {

    }, 'MessageHover.png', 'Message.png', 'Message.png').setVisible(false);

    this.noMsg = scene.add.image(-850, 100, 'noMsg').setOrigin(0.5, 0.5).setScale(2).setVisible(false);


    this.btnWallet = new Button(scene, 550, 100, 'btnWallet', () => {
      eventBus.emit('modal:wallet');
    }, 'gold', 'blue', 'gold');

    this.btnSetting = new Button(scene, 800, 100, 'settingsBot', () => {
      this.settings.show();
    }, 'SettingBotHover.png', 'SettingBot.png', 'SettingBot.png');

    this.btnLock = new Button(scene, -300, 100, 'btnLock', () => {
      scene.scene.switch('LoginScene');
    }, 'hover', 'default', 'hover');
    this.textLock =  scene.add.text(-300, 200, scene.game.config[scene.game.config.locale]['GAME']['LOCK'], {
      fontFamily: 'electrolize',
      fontSize: 28
    }).setOrigin(0.5, 0.5);

    this.btnExit = new Button(scene, 300, 100, 'btnExit', async () => {
      eventBus.emit('app:close');
    }, 'hover', 'default', 'hover')//.setVisible(false);
    this.textExit = scene.add.text(300, 200, scene.game.config[scene.game.config.locale]['GAME']['EXIT'], {
      fontFamily: 'electrolize',
      fontSize: 28
    }).setOrigin(0.5, 0.5)//.setVisible(false);


    this.btnRealChips = new Button(scene, -850, 100, 'btnBuy', () => {
      scene.scene.switch('ChipsScene');
      scene.scene.get('PreScene').soundDoor.play();
    }, 'hover', 'default', 'hover').setScale(1.1);

    this.sellChips = new Button(scene, -600, 100, 'btnSell', () => {
      eventBus.emit('modal:sellchips');
    }, 'hover', 'default', 'hover').setScale(0.88);

    this.add([
      scene.add.image(0, 255, 'menuBot').setOrigin(0.5, 1),

      this.btnRealChips,
      this.sellChips,
      scene.add.text(-850, 200, scene.game.config[scene.game.config.locale]['GAME']['BUY_CHIPS'], {
        fontFamily: 'electrolize',
        fontSize: 28
      }).setOrigin(0.5, 0.5),
      scene.add.text(-600, 200, scene.game.config[scene.game.config.locale]['GAME']['SELL_CHIPS'], {
        fontFamily: 'electrolize',
        fontSize: 28
      }).setOrigin(0.5, 0.5),
     this.textLock,

      this.textExit,

      scene.add.text(550, 200, scene.game.config[scene.game.config.locale]['WALLET']['WALLET'], {
        fontFamily: 'electrolize',
        fontSize: 28
      }).setOrigin(0.5, 0.5),
      scene.add.text(800, 200, scene.game.config[scene.game.config.locale]['APP']['SETTINGS'].toUpperCase(), {
        fontFamily: 'electrolize',
        fontSize: 28
      }).setOrigin(0.5, 0.5),

      /*
      scene.add.text(-850, 200, 'MESSAGES', {
        fontFamily: 'electrolize',
        fontSize: 28
      }).setOrigin(0.5, 0.5),
       */


      this.btnSetting,
      this.btnWallet,
      this.btnMessage,
      this.noMsg,
      this.btnLock,
      this.btnExit,
    ]);



    this.tweenPanel = scene.tweens.add({
      targets: [this],
      y: '-=255',
      ease: 'Sine.easeInOut',
      duration: 400,
      onStart: async function () {
        scene.soundDoor.play();
      },
    });

    this.AnimSettings = scene.tweens.add({
      targets: [this.btnSetting],
      angle: 360,
      ease: 'Sine.easeInOut',
      duration: 800,
      yoyo: true
    });

  }
}
