import eventBus from '@/plugins/event-bus';
import Button from '../../class/button';
import store from '@/store';

export default class LobbyTopPanel extends Phaser.GameObjects.Container {
  constructor(scene, x, y) {
    super(scene, x, y);
    scene.add.existing(this);

    let maxPlayers = 'ANY';
    let taken = false;

    this.btnTables =new Button(scene, 580, 90, 'btnNormal', async () => {
      taken = !taken;
      textShowTaken.text = taken ? scene.game.config[scene.game.config.locale]['GAME']['SHOW'] : scene.game.config[scene.game.config.locale]['GAME']['HIDE'];
      //eventBus.emit('lobby:filter', {maxPlayers: maxPlayers, taken: taken});
      await store.dispatch('games/lobbyFilter', {maxPlayers: maxPlayers > 0 ? maxPlayers : null, taken: taken});
    }, 'btn_press.png', 'btn_normal.png', 'btn_press.png').setScale(0.86);

    this.btnSits =new Button(scene, 580 + 310, 90, 'btnNormal', async () => {
      let pText = scene.game.config[scene.game.config.locale]['GAME']['ANY'];
      if (maxPlayers === 'ANY') {
        maxPlayers = 5;
        pText = maxPlayers;
      } else {
        if (maxPlayers === 9) {
          maxPlayers = 'ANY';
          pText = scene.game.config[scene.game.config.locale]['GAME']['ANY'];
        }
        if (maxPlayers === 5) {
          maxPlayers = 9;
          pText = maxPlayers;
        }
      }
      textMaxPlayers.text = pText;
      //eventBus.emit('lobby:filter', {maxPlayers: maxPlayers, taken: true});
      await store.dispatch('games/lobbyFilter', {maxPlayers: maxPlayers > 0 ? maxPlayers : null, taken: taken});
    }, 'btn_press.png', 'btn_normal.png', 'btn_press.png').setScale(0.86);

    this.btnSpeed =new Button(scene, 580 + 310 * 2, 90, 'btnNormal', () => {
    }, 'btn_press.png', 'btn_normal.png', 'btn_press.png').setScale(0.86).setAlpha(0.3);

    this.btnVip =new Button(scene, 580 + 310 * 3, 90, 'btnNormal', () => {
    }, 'btn_press.png', 'btn_normal.png', 'btn_press.png').setScale(0.86).setAlpha(0.3);

    this.chips = scene.add.text(230, 137, store.getters['account/player'].chips.real, {
      fontFamily: 'electrolize',
      fontSize: 22,
      color: '#FFFFFF',// '#0891c5',
    }).setOrigin(0, 0.5);

    eventBus.on('update:balance', async (player) => {
      scene.game.config.player = player;
      this.setBalanceChips(player.chips.real);
    });

    this.playerName = scene.add.text(210, 95, store.getters['account/player']['name'] || 'Guest', {
      fontFamily: 'electrolize',
      fontSize: 22,
      color: '#FFFFFF',// '#0891c5',
    }).setOrigin(0, 0.5);

    let _self = this;
    eventBus.on('player:update', function (player) {
      _self.playerName.text = player.name;
      _self.chips.text = player.chips.real;
      _self.avatar.setFrame(player.sex);
    });

    this.btnBack = new Button(scene, scene.game.config.width - 125, 90, 'btnPrev', async () => {
      this.tweenPanel.resume();
      scene.scene.switch('GameMenu');
      //scene.scene.get('PreScene').soundDoor.play();
      await store.dispatch('games/getLobby');
    }, 'btn_prev_hover.png', 'btn_prev.png', 'btn_prev.png').setOrigin(0.5, 0.5);

    this.panel = scene.add.image(scene.game.config.width / 2, -35, 'menuTop').setOrigin(0.5, 0);

    this.player = scene.add.image(220, 100, 'battleYou').setOrigin(0.5, 0.5).setScale(0.6).setInteractive();
    this.player.on('pointerdown', async function () {
      eventBus.emit('modal:settings');
    }, this);

    this.avatar = scene.add.image(115, 90, 'avatars', store.getters['account/player']['sex'] || 'alien')
      .setOrigin(0.5, 0.5).setScale(0.44);

    let textMaxPlayers = scene.add.text(580 + 310, 105, maxPlayers, {
        fontFamily: 'electrolize',
        fontSize: 26,
        color: '#da9e00'
      }).setOrigin(0.5, 0.5);

    let textShowTaken = scene.add.text(580, 105, scene.game.config[scene.game.config.locale]['GAME']['HIDE'], {
      fontFamily: 'electrolize',
      fontSize: 26,
      color: '#da9e00'
    }).setOrigin(0.5, 0.5);

    /** container **/
    this.add([
      this.panel,
      this.player,
      this.avatar,
      scene.add.image(185, 137, 'chipIcon').setOrigin(0, 0.5).setScale(0.29),
      this.playerName,
      this.chips,
      this.btnBack,
      //scene.add.image(scene.game.config.width - 125, 90, 'oponent',).setOrigin(0.5, 0.5).setScale(0.6),
      this.btnTables,
      scene.add.text(580, 70, scene.game.config[scene.game.config.locale]['GAME']['TB_TAKEN'], {
        fontFamily: 'electrolize',
        fontSize: 26,
        color: '#00f7ff'
      }).setOrigin(0.5, 0.5),
      textShowTaken,

      this.btnSits,
      scene.add.text(580 + 310, 70, scene.game.config[scene.game.config.locale]['GAME']['SITS'], {
        fontFamily: 'electrolize',
        fontSize: 26,
        color: '#00f7ff'
      }).setOrigin(0.5, 0.5),
      textMaxPlayers,


      this.btnSpeed,
      scene.add.text(580 + 310 * 2, 70, scene.game.config[scene.game.config.locale]['GAME']['SPEED'], {
        fontFamily: 'electrolize',
        fontSize: 26,
        color: '#00f7ff'
      }).setOrigin(0.5, 0.5).setAlpha(0.3),
      scene.add.text(580 + 310 * 2, 105, scene.game.config[scene.game.config.locale]['GAME']['ANY'], {
        fontFamily: 'electrolize',
        fontSize: 26,
        color: '#da9e00'
      }).setOrigin(0.5, 0.5).setAlpha(0.3),

      this.btnVip,
      scene.add.text(580 + 310 * 3, 70, 'VIP', {
        fontFamily: 'electrolize',
        fontSize: 26,
        color: '#00f7ff'
      }).setOrigin(0.5, 0.5).setAlpha(0.3),
      scene.add.text(580 + 310 * 3, 105, 'DEFAULT', {
        fontFamily: 'electrolize',
        fontSize: 26,
        color: '#da9e00'
      }).setOrigin(0.5, 0.5).setAlpha(0.3),
    ]);


    this.tweenPanel = scene.tweens.add({
      targets: [this],
      y: '0',
      ease: 'Sine.easeInOut',
      duration: 900,
      onStart: async function () {
        //scene.soundDoor.play();
      },
    })

  }


  setBalanceChips(amount) {
    this.chips.text = amount;
  }

}
