import eventBus from '@/plugins/event-bus';
import store from '@/store';

export default class CounterGames extends Phaser.GameObjects.Container {
  constructor(scene, x, y) {
    super(scene, x, y);
    scene.add.existing(this);
    let _self = this;
    //this.setVisible(false);
    const counter = store.getters['app/counter'];

    //this.icon = scene.add.image(0, 0, 'iconProfile').setOrigin(0.5, 0.5).setScale(0.7);
    this.countGames = scene.add.text(0, 0, 'completed '+counter.games.reduce((partial_sum, a) => partial_sum + a,0), {
      fontFamily: 'electrolize',
      fontSize: 32,
      color: '#91fdfe'
    }).setOrigin(1, 0.5);
    eventBus.on('app:counter:update', async function (data) {
      _self.countGames.text = 'completed '+data.games.reduce((partial_sum, a) => partial_sum + a,0);
    });

    this.add([
      //this.icon,
      this.countGames
    ]);

    this.animCounter = scene.tweens.add({
      targets: this,
      scale: {from: 0.1, to: 1},
      alpha: {from: 0, to: 1},
      ease: 'Sine.easeInOut',
      duration: 1500,
      //paused: true,
    });
  }


}
