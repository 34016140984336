export default class CommunityCards extends Phaser.GameObjects.Container {
  constructor(scene, x, y, cards = []) {
    super(scene, x, y);
    scene.add.existing(this);
    //this.setVisible(false);

    this.communityCards = [];
    for (let i = 0; i < 5; i++) {
      this.communityCards[i] = scene.add.image(x - 240 + (120 * i), y - 53, 'cards', 'back-gray')
        .setOrigin(0.5, 0.5).setScale(0.91).setVisible(true); // prev scale 0.5
    }
  }

  setCards(cards = []) {
    for (let i = 0; i < 5; i++) {
      this.communityCards[i].setFrame('back-gray').setVisible(false);
    }

    //show cards
    for (let i = 0; i < cards.length; i++) {
      this.communityCards[i].setFrame(cards[i]._rank + cards[i]._suit).setVisible(true);
    }

  }

}
