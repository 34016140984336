export default class Bank extends Phaser.GameObjects.Container {
  constructor(scene, x, y) {
    super(scene, x, y);
    scene.add.existing(this);
    this.setVisible(false);
    this.add(scene.add.image(0, -189, 'BankChips').setOrigin(0.5, 0.5).setScale(1.20));
    this.amount = scene.add.text(10, -179, '0', {
      fontFamily: 'electrolize',
      fontSize: 29
    }).setOrigin(0.5, 0.5);
    this.add(this.amount);
  }

  setBank(amount) {
    this.setVisible(amount > 0);
    this.amount.text = Math.floor(amount);
  }

}
