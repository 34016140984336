import Button from '../class/button';
export default class Rules extends Phaser.GameObjects.Container {
  constructor(scene, x, y) {
    super(scene, x, y);
    scene.add.existing(this);
    this.setVisible(false);

    const actionClose = () => {
      this.setVisible(false);
    };

    let w = 620;
    let h = 1030;
    const octagon = scene.add.polygon(0, 0, [20, 0, w, 0, w + 20, 15, w + 20, h, w, h + 20, 20, h + 20, 0, h, 0, 15], 0x05182f)
      .setAlpha(1).setOrigin(0, 0).setInteractive();
    octagon.setStrokeStyle(5, 0x2d92a9);
    this.add(octagon);

    octagon.on('pointerdown', function () {
      actionClose();
    }, this);

    /** cards **/

    const rule = [
      {
        rank: 'Royal Flush',
        cards: ['Ah', 'Kh', 'Qh', 'Jh', 'Th']
      },
      {
        rank: 'Straight Flush',
        cards: ['9d', '8d', '7d', '6d', '5d']
      },
      {
        rank: 'Four of a Kind',
        cards: ['Ac', 'Ah', 'As', 'Ad', '9s']
      },
      {
        rank: 'Full House',
        cards: ['Qh', 'Qs', 'Qd', 'Jc', 'Jd']
      },
      {
        rank: 'Flush',
        cards: ['Ah', 'Jh', '9h', '6h', '3h']
      },
      {
        rank: 'Straight',
        cards: ['9c', '8d', '7s', '6d', '5s']
      },
      {
        rank: 'Three of a Kind',
        cards: ['5d', 'Qc', '2h', '2s', '2d']
      },
      {
        rank: 'Two Pair',
        cards: ['Ah', 'Ac', 'Kh', 'Ks', '9d']
      },
      {
        rank: 'One Pair',
        cards: ['4h', '5s', '8c', 'Ad', 'As']
      },
      {
        rank: 'High Card',
        cards: ['5s', '6d', 'Jc', 'Qd', 'As']
      }
    ];


    for (let i=0; i < rule.length; i++) {
      for (let j=0; j < 5; j++) {
        this.add([scene.add.image(20 + j * 74, 18 + i * 102, 'cards', rule[i].cards[j]).setScale(0.55).setOrigin(0, 0)]);
      }
      let graphics = scene.add.graphics();
      graphics.fillStyle(0x000000, 0.5);
      graphics.fillRoundedRect(396, 18 + i * 102, 230, 98, 8)

      this.add([
        graphics,
        scene.add.text(500, 66 + i * 102, rule[i].rank, {
          fontFamily: 'electrolize',
          fontSize: 26,
          fill: '#aed7f1'
        }).setOrigin(0.5, 0.5)
      ]);


      this.add(new Button(scene, 590, 55, 'btnFormClose', actionClose, 'close_hover', 'close', 'close_hover').setOrigin(0.5, 0.5).setScale(0.72));

      this.add(new Button(scene, 590, 996, 'btnFormClose', actionClose, 'close_hover', 'close', 'close_hover').setOrigin(0.5, 0.5).setScale(0.72).setFlipY(true));

    }


  }
}
