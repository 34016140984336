export default class PlayerTime extends Phaser.GameObjects.Container {
  constructor(scene, x, y) {
    super(scene, x, y);
    scene.add.existing(this);
    this.setVisible(false);
    this.timerId = null;


    this.clock = scene.add.text(0, 0, '20', {
      fontFamily: 'electrolize',
      fontSize: 38,
      strokeThickness: 2, stroke: '#076d2f',
      fill: '#FFFFFF',
    }).setOrigin(0.5, 0.5);

    this.timeColor = scene.add.image(0, 0, 'timer', 'green')
      .setOrigin(0.5, 0.5).setScale(0.45).setName('timerGreen');

    this.add([
      this.timeColor,
      this.clock
    ]);


  }


  playerTimeUpdate() {
    this.startTime--;
    this.clock.text = this.startTime;
    if (this.startTime < 14) {
      this.timeColor.setFrame('orange');
    }

    if (this.startTime < 6) {
      this.timeColor.setFrame('red');
    }

    if (this.startTime < 1) {
      clearTimeout(this.timerId);
      this.setVisible(false);
      this.startTime = 20;
      this.clock.text = this.startTime;
      this.timeColor.setFrame('green');
    }
  }

  startTimer() {
    this.setVisible(true);
    this.startTime = 20;
    this.clock.text = this.startTime;
    this.timeColor.setFrame('green');
    let _self = this;
    clearTimeout(this.timerId);
    this.timerId = setTimeout(function tick() {
      _self.playerTimeUpdate();
      _self.timerId = setTimeout(tick, 1000);
    }, 1000);
  }

  stopTimer() {
    clearTimeout(this.timerId);
    this.setVisible(false);
  }


}
