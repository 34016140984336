export default class PlayerBet extends Phaser.GameObjects.Container {
  constructor(scene, x, y, amount = 0) {
    super(scene, x, y);
    scene.add.existing(this);
    this.setVisible(amount > 0);
    //this.setVisible(true);

    this.amount = scene.add.text(51, 0, amount, {
      fontFamily: 'electrolize',
      strokeThickness: 1, stroke: '#282727',
      fontSize: 26
    }).setOrigin(0.5, 0.5);

    this.add([
      scene.add.image(-21, 0, 'betField').setOrigin(0, 0.5).setScale(1.16),
      scene.add.image(-2, 0, 'chipBlack').setOrigin(0.5, 0.5).setScale(0.90),
      this.amount
    ]);
  }

  setAmount(amount) {
    this.amount.text = amount;
    this.setVisible(amount > 0)
  }
}
