export default class PlusChips extends Phaser.GameObjects.Container {
  constructor(scene, x, y) {
    super(scene, x, y);
    scene.add.existing(this);
    this.setVisible(false);

    this.textChips = scene.add.text(0, 0, '', {
      fontFamily: 'electrolize',
      fontSize: 21,
      color: '#FFFFFF',
      strokeThickness: 1, stroke: '#030a12',
    }).setOrigin(0, 0.5);

    this.add(this.textChips);
  }

  show() {
    this.setVisible(true);
  }

  hide() {
    this.setVisible(false);
  }

  setAmount(amount) {
    this.textChips.text = '+' + Math.floor(amount);
  }

}
