export default class LobbyPlayer extends Phaser.GameObjects.Container {
  constructor(scene, x, y) {
    super(scene, x, y);
    scene.add.existing(this);
    this.setVisible(false);

    this.avBg = scene.add.image(0, 0, 'avBack').setOrigin(0.5, 0.5).setScale(0.57);
    this.avatar = scene.add.image(0, 0, 'avatars', 'male').setOrigin(0.5, 0.5).setScale(0.55);
    this.playerName = scene.add.text(0, -85, 'init', {
      fontFamily: 'electrolize',
      fontSize: 22,
      color: '#ffffff',
      fontWeight: 'light'
    }).setOrigin(0.5, 0.5).setName('playerName');
    this.chip = scene.add.image(-28, 95, 'chipBlack',).setOrigin(1, 0.5).setScale(0.62);
    this.balance = scene.add.text(10, 95, 0, {
      fontFamily: 'electrolize',
      fontSize: 21,
      color: '#ffffff'
    }).setOrigin(0.5, 0.5).setName('playerStack');

    this.add([
      this.avBg,
      this.avatar,
      this.playerName,
      this.chip,
      this.balance,
    ]);

  }

  setPlayer(data) {
    this.playerName.text = data.name;
    this.balance.text = data.stackSize;
    this.avatar.setFrame(data.sex);

    this.setVisible(true);

    if (data.left) {
      this.setVisible(false);
    }
  }

  hide() {
    this.setVisible(false);
  }
}
