import {Scene} from 'phaser'
import Button from './class/button';
import CryptoJS from 'crypto-js';
import store from '@/store';
import eventBus from '@/plugins/event-bus';
import Overlay from './Modules/Overlay';
import packageJson from '../../../package.json'

export default class LoginScene extends Scene {

  constructor() {
    super({key: 'LoginScene'})
  }

  create() {
    //this.game.config.ru = this.cache.json.get('ru');
    //this.game.config.en = this.cache.json.get('en');
    //this.game.config.locale = store.getters['app/language'];
    let pinString = '';
    const actionBtnSignIn = () => {
      const pinEncrypted = store.getters['app/pinAES'];
      //this.container.destroy(true);
      this.enterPin.setVisible(pinString.length < 1);
        if (pinString.length > 3 && pinEncrypted) {
          const currentHashPin = (CryptoJS.SHA384(pinString.toString())).toString();
          const decryptCompare = (CryptoJS.AES.decrypt(pinEncrypted, currentHashPin)).toString(CryptoJS.enc.Utf8)
          if (decryptCompare && decryptCompare === currentHashPin) {
            const account = store.getters['account/account'];
            const settings = store.getters['app/settings'];
            store.dispatch('account/getPlayer', {
              name: settings.name,
              userId: account.userId,
              pubKey: account.pubKey,
              gameUserId: account.gameChain.userId || null,
            });

            this.game.config.player = store.getters['account/player'];
            this.game.config.pin = currentHashPin;
            eventBus.emit('player:login', {
              userId: account.userId,
              pin: currentHashPin,
            });
            //this.scene.sleep('LoginScene');
            pinString = '';
            pinText.text = '';
            this.enterPin.setVisible(true);
            store.dispatch('games/setPlayerStatus', null);
            //this.scale.startFullscreen();
            this.scene.switch('PreScene');
          } else {
            //animBtn.resume();

          }
        }

      if (pinString.length === 0) {
        this.cameras.main.shake(400, 0.004,);
      }
    };

    this.add.image(this.game.config.width / 2, this.game.config.height / 2, 'bgStars').setOrigin(0.5, 0.5);
    //let overlay = this.add.image(this.game.config.width / 2, this.game.config.height / 2, 'overlay').setOrigin(0.5, 0.5);

    let btnSignIn = new Button(this, this.game.config.width / 2, this.game.config.height / 2 + 363 - 111, 'btnUnlock', actionBtnSignIn, 'btn_unlock_hover.png', 'btn_unlock.png', 'btn_unlock.png');
    let form = this.add.image(this.game.config.width / 2, this.game.config.height / 2, 'formBg').setOrigin(0.5, 0.5);
    let inputPinHover = this.add.image(this.game.config.width / 2, this.game.config.height / 2 - 275, 'inputPinHover').setOrigin(0.5, 0.5);



    let animBtn = this.tweens.add({
      targets: btnSignIn,
      //alpha: {from: 0, to: 1},
      y: '+=111',
      ease: 'Elastic',       // 'Cubic', 'Elastic', 'Bounce', 'Back'
      duration: 500,
    });


    let title = this.add.text(this.game.config.width / 2, this.game.config.height / 2 - 400, this.game.config[this.game.config.locale]['APP']['UNLOCK'], {
      fontFamily: 'electrolize',
      fontSize: 42,
      color: '#b1d8f1'
    }).setOrigin(0.5, 0.5);

    this.add.text(this.game.config.width / 2, this.game.config.height / 2 - 360, this.game.config[this.game.config.locale]['GAME']['GAME1'], {
      fontFamily: 'electrolize',
      fontSize: 28,
      color: '#b1d8f1'
    }).setOrigin(0.5, 0.5);

    this.enterPin = this.add.text(this.game.config.width / 2 + 30, this.game.config.height / 2 - 270, this.game.config[this.game.config.locale]['GAME']['ENTER_PIN'], {
      fontFamily: 'electrolize',
      fontSize: 42,
      color: '#61d4f1'
    }).setOrigin(0.5, 0.5).setAlpha(0.3);

    let pinText = this.add.text(this.game.config.width / 2 + 25, this.game.config.height / 2 - 265, pinString, {
      fontFamily: 'electrolize',
      fontSize: 48,
      color: '#b1d8f1'
    }).setOrigin(0.5, 0.5);


    const actionPinPress1 = () => {
      if (pinText.text.length < 20) {
        pinString = pinString + '1';
        pinText.text = pinText.text + '*';
        actionBtnSignIn();
      }
    };

    const actionPinPress2 = () => {
      if (pinText.text.length < 20) {
        pinString = pinString + '2';
        pinText.text = pinText.text + '*';
        actionBtnSignIn();
      }
    };

    const actionPinPress3 = () => {
      if (pinText.text.length < 20) {
        pinString = pinString + '3';
        pinText.text = pinText.text + '*';
        actionBtnSignIn();
      }
    };

    const actionPinPress4 = () => {
      if (pinText.text.length < 20) {
        pinString = pinString + '4';
        pinText.text = pinText.text + '*';
        actionBtnSignIn();
      }
    };

    const actionPinPress5 = () => {
      if (pinText.text.length < 20) {
        pinString = pinString + '5';
        pinText.text = pinText.text + '*';
        actionBtnSignIn();
      }
    };

    const actionPinPress6 = () => {
      if (pinText.text.length < 20) {
        pinString = pinString + '6';
        pinText.text = pinText.text + '*';
        actionBtnSignIn();
      }
    };

    const actionPinPress7 = () => {
      if (pinText.text.length < 20) {
        pinString = pinString + '7';
        pinText.text = pinText.text + '*';
        actionBtnSignIn();
      }
    };

    const actionPinPress8 = () => {
      if (pinText.text.length < 20) {
        pinString = pinString + '8';
        pinText.text = pinText.text + '*';
        actionBtnSignIn();
      }
    };

    const actionPinPress9 = () => {
      if (pinText.text.length < 20) {
        pinString = pinString + '9';
        pinText.text = pinText.text + '*';
        actionBtnSignIn();
      }
    };

    const actionPinPress0 = () => {
      if (pinText.text.length < 20) {
        pinString = pinString + '0';
        pinText.text = pinText.text + '*';
        actionBtnSignIn();
      }
    };

    /*
    const actionPinPressA = () => {
      if (pinText.text.length < 20) {
        pinString = pinString + 'A';
        pinText.text = pinText.text + '*';
        actionBtnSignIn();
      }
    };
     */

    const actionPinPressX = () => {
      pinText.text = '';
      pinString = '';
      this.enterPin.setVisible(true)
    };


    /*
    document.addEventListener('keydown', async (event) => {
      //0
      if (event.keyCode === 48) {
        actionPinPress0();
      }

      //1
      if (event.keyCode === 49) {
        actionPinPress1();
      }

      //2
      if (event.keyCode === 50) {
        actionPinPress2();
      }

      //3
      if (event.keyCode === 51) {
        actionPinPress3();
      }

      //4
      if (event.keyCode === 52) {
        actionPinPress4();
      }

      //5
      if (event.keyCode === 53) {
        actionPinPress5();
      }

      //6
      if (event.keyCode === 54) {
        actionPinPress6();
      }

      //7
      if (event.keyCode === 55) {
        actionPinPress7();
      }

      //8
      if (event.keyCode === 56) {
        actionPinPress8();
      }

      //9
      if (event.keyCode === 57) {
        actionPinPress9();
      }

      //A
      if (event.keyCode === 65) {
        actionPinPressA();
      }

      //X
      if (event.keyCode === 88) {
        actionPinPressX();
      }

      //DEL
      if (event.keyCode === 46) {
        actionPinPressX();
      }

      //Backspace DEL
      if (event.keyCode === 8) {
        actionPinPressX();
      }

    });
    */








    let startPinX = 660;
    let startPinY = -100;
    let pinScale = 0.57;

    let btnPin1 = new Button(this, startPinX, this.game.config.height / 2 + startPinY, 'pinNum', actionPinPress1, 'pin_num_hover.png', 'pin_num.png', 'pin_num_press.png').setScale(pinScale);
    let text1 = this.add.text(startPinX, this.game.config.height / 2 + startPinY + 5, '1', {
      fontFamily: 'electrolize',
      fontSize: 86,
      strokeThickness: 2, stroke: '#000000',
    }).setOrigin(0.5, 0.5);

    let btnPin2 = new Button(this, startPinX + 150, this.game.config.height / 2 + startPinY, 'pinNum', actionPinPress2, 'pin_num_hover.png', 'pin_num.png', 'pin_num_press.png').setScale(pinScale);
    let text2 = this.add.text(startPinX + 150, this.game.config.height / 2 + startPinY + 5, '2', {
      fontFamily: 'electrolize',
      fontSize: 86,
      strokeThickness: 2, stroke: '#000000',
    }).setOrigin(0.5, 0.5);

    let btnPin3 = new Button(this, startPinX + 150 * 2, this.game.config.height / 2 + startPinY, 'pinNum', actionPinPress3, 'pin_num_hover.png', 'pin_num.png', 'pin_num_press.png').setScale(pinScale);
    let text3 = this.add.text(startPinX + 150 * 2, this.game.config.height / 2 + startPinY + 5, '3', {
      fontFamily: 'electrolize',
      fontSize: 86,
      strokeThickness: 2, stroke: '#000000',
    }).setOrigin(0.5, 0.5);

    let btnPin4 = new Button(this, startPinX + 150 * 3, this.game.config.height / 2 + startPinY, 'pinNum', actionPinPress4, 'pin_num_hover.png', 'pin_num.png', 'pin_num_press.png').setScale(pinScale);
    let text4 = this.add.text(startPinX + 150 * 3, this.game.config.height / 2 + startPinY + 5, '4', {
      fontFamily: 'electrolize',
      fontSize: 86,
      strokeThickness: 2, stroke: '#000000',
    }).setOrigin(0.5, 0.5);

    let btnPin5 = new Button(this, startPinX + 150 * 4, this.game.config.height / 2 + startPinY, 'pinNum', actionPinPress5, 'pin_num_hover.png', 'pin_num.png', 'pin_num_press.png').setScale(pinScale);
    let text5 = this.add.text(startPinX + 150 * 4, this.game.config.height / 2 + startPinY + 5, '5', {
      fontFamily: 'electrolize',
      fontSize: 86,
      strokeThickness: 2, stroke: '#000000',
    }).setOrigin(0.5, 0.5);

    /** Pin Row - 2 **/

    startPinY = 30;
    startPinX = 660 + 75;

    let btnPin6 = new Button(this, startPinX, this.game.config.height / 2 + startPinY, 'pinNum', actionPinPress6, 'pin_num_hover.png', 'pin_num.png', 'pin_num_press.png').setScale(pinScale);
    let text6 = this.add.text(startPinX, this.game.config.height / 2 + startPinY + 5, '6', {
      fontFamily: 'electrolize',
      fontSize: 86,
      strokeThickness: 2, stroke: '#000000',
    }).setOrigin(0.5, 0.5);

    let btnPin7 = new Button(this, startPinX + 150, this.game.config.height / 2 + startPinY, 'pinNum', actionPinPress7, 'pin_num_hover.png', 'pin_num.png', 'pin_num_press.png').setScale(pinScale);
    let text7 = this.add.text(startPinX + 150, this.game.config.height / 2 + startPinY + 5, '7', {
      fontFamily: 'electrolize',
      fontSize: 86,
      strokeThickness: 2, stroke: '#000000',
    }).setOrigin(0.5, 0.5);

    let btnPin8 = new Button(this, startPinX + 150 * 2, this.game.config.height / 2 + startPinY, 'pinNum', actionPinPress8, 'pin_num_hover.png', 'pin_num.png', 'pin_num_press.png').setScale(pinScale);
    let text8 = this.add.text(startPinX + 150 * 2, this.game.config.height / 2 + startPinY + 5, '8', {
      fontFamily: 'electrolize',
      fontSize: 86,
      strokeThickness: 2, stroke: '#000000',
    }).setOrigin(0.5, 0.5);

    let btnPin9 = new Button(this, startPinX + 150 * 3, this.game.config.height / 2 + startPinY, 'pinNum', actionPinPress9, 'pin_num_hover.png', 'pin_num.png', 'pin_num_press.png').setScale(pinScale);
    let text9 = this.add.text(startPinX + 150 * 3, this.game.config.height / 2 + startPinY + 5, '9', {
      fontFamily: 'electrolize',
      fontSize: 86,
      strokeThickness: 2, stroke: '#000000',
    }).setOrigin(0.5, 0.5);


    startPinY = 30 + 130;
    startPinX = 210;
    let btnPin0 = new Button(this, startPinX + 150 * 4, this.game.config.height / 2 + startPinY, 'pinNum', actionPinPress0, 'pin_num_hover.png', 'pin_num.png', 'pin_num_press.png').setScale(pinScale);
    let text0 = this.add.text(startPinX + 150 * 4, this.game.config.height / 2 + startPinY + 5, '0', {
      fontFamily: 'electrolize',
      fontSize: 86,
      strokeThickness: 2, stroke: '#000000',
    }).setOrigin(0.5, 0.5);

/*
    let btnPinA = new Button(this, startPinX + 150 * 5, this.game.config.height / 2 + startPinY, 'pinNum', actionPinPressA, 'pin_num_hover.png', 'pin_num.png', 'pin_num_press.png').setScale(pinScale);
    let textA = this.add.text(startPinX + 150 * 5, this.game.config.height / 2 + startPinY + 5, 'A', {
      fontFamily: 'electrolize',
      fontSize: 86,
      strokeThickness: 2, stroke: '#000000',
    }).setOrigin(0.5, 0.5);
 */

    let btnPinDel = new Button(this, startPinX + 150 * 5, this.game.config.height / 2 + startPinY, 'pinNum', actionPinPressX, 'pin_num_hover.png', 'pin_num.png', 'pin_num_press.png').setScale(pinScale);
    let textDel = this.add.text(startPinX + 150 * 5, this.game.config.height / 2 + startPinY + 5, 'X', {
      fontFamily: 'electrolize',
      fontSize: 86,
      color: '#dd3451',
      strokeThickness: 2, stroke: '#000000',
    }).setOrigin(0.5, 0.5);

    this.add.text(this.game.config.width / 2, 40, 'v.'+packageJson.version, {
      fontFamily: 'electrolize',
      fontSize: 24,
      color: '#fafafa',
      strokeThickness: 2, stroke: '#000000',
    }).setOrigin(0.5, 0.5).setAlpha(0.3);



/*
    this.reset = new Button(this, this.game.config.width - 275, this.game.config.height / 2 + 260, 'resetAll', () => {
      eventBus.emit('modal:reset');
    }, 'reset_all_hover', 'reset_all', 'reset_all_hover');
 */

    this.resetMin = new Button(this, this.game.config.width / 2 + 240, this.game.config.height / 2 + 196, 'resetAll', () => {
      eventBus.emit('modal:reset');
    }, 'reset_all_hover', 'reset_all', 'reset_all_hover').setScale(0.6);

    this.btnExit = new Button(this, 660, 800, 'btnExit', async () => {
      eventBus.emit('app:close');
    }, 'hover', 'default', 'hover').setScale(0.8);

    this.overlay = new Overlay(this);
    const account = store.getters['account/account'];
    if (!account.userId) {
      this.overlay.setVisible(true)
    }

  }




}
