exports.ninePlayers = {
  sits: [
    {
      id: null,
      current: false,
      avatar: "av",
      name: "Vlad",
      chips: 1000,
      x: 700,
      y: 130,
      dealer: {
        show: false,
        x: 848,
        y: 235,
      },
      hiddenCards: {
        show: false,
        x: 800,
        y: 225,
      },
      chipBet: {
        flip: false,
        show: false,
        x: 680,
        y: 270,
      }
    },
    {
      id: null,
      current: false,
      avatar: "av",
      name: "Валера",
      chips: 1000,
      x: 1200,
      y: 130,
      dealer: {
        show: true,
        x: 1040,
        y: 235,
      },
      hiddenCards: {
        show: true,
        x: 1095,
        y: 225,
      },
      chipBet: {
        flip: true,
        show: true,
        x: 1155,
        y: 270,
      },
    },
    {
      id: null,
      current: false,
      avatar: "av",
      name: "TechnoL0g",
      chips: 1000,
      x: 1520,
      y: 230,
      dealer: {
        show: true,
        x: 1345,
        y: 305,
      },
      hiddenCards: {
        show: true,
        x: 1400,
        y: 295,
      },
      chipBet: {
        show: true,
        flip: true,
        x: 1340,
        y: 352,
      },
    },
    {
      id: null,
      current: false,
      avatar: "av",
      name: "Europa",
      chips: 1000,
      x: 1645,
      y: 500,
      dealer: {
        show: true,
        x: 1450,
        y: 500,
      },
      hiddenCards: {
        show: true,
        x: 1505,
        y: 490,
      },
      chipBet: {
        show: true,
        flip: true,
        x: 1450,
        y: 550,
      },
    },
    {
      id: null,
      current: false,
      avatar: "av",
      name: "Guapa",
      chips: 1000,
      x: 1470,
      y: 810,
      dealer: {
        show: true,
        x: 1320,
        y: 705,
      },
      hiddenCards: {
        show: true,
        x: 1370,
        y: 720,
      },
      chipBet: {
        show: true,
        flip: true,
        x: 1310,
        y: 654,
      },
    },
    /** Main Player **/
    {
      id: null,
      current: false,
      avatar: "av",
      name: "Smart",
      chips: 1000,
      x: 960,
      y: 840,
      dealer: {
        show: true,
        x: 940,
        y: 695,
      },
      hiddenCards: {
        show: true,
        x: 1370,
        y: 720,
      },
      chipBet: {
        show: true,
        flip: true,
        x: 1050,
        y: 690,
      },
    },
    /** Main Player END **/
    {
      id: null,
      current: false,
      avatar: "av",
      name: "XBTS Exchange",
      chips: 1000,
      x: 600,
      y: 830,
      dealer: {
        show: true,
        x: 615,
        y: 690,
      },
      hiddenCards: {
        show: true,
        x: 732,
        y: 780,
      },
      chipBet: {
        show: true,
        flip: true,
        x: 680,
        y: 685,
      },
    },
    {
      id: null,
      current: false,
      avatar: "av",
      name: "Guest",
      chips: 1000,
      x: 290,
      y: 586,
      dealer: {
        show: true,
        x: 500,
        y: 580,
      },
      hiddenCards: {
        show: true,
        x: 430,
        y: 585,
      },
      chipBet: {
        show: true,
        flip: true,
        x: 440,
        y: 525,
      },
    },
    {
      id: null,
      current: false,
      avatar: "av",
      name: "Mystericon",
      chips: 1000,
      x: 350,
      y: 300,
      dealer: {
        show: true,
        x: 535,
        y: 310,
      },
      hiddenCards: {
        show: true,
        x: 490,
        y: 300,
      },
      chipBet: {
        show: true,
        flip: false,
        x: 490,
        y: 365,
      },
    }
  ]
};


exports.fivePlayers = {
  sits: [
    {
      id: null,
      current: false,
      avatar: "av",
      name: "Vlad",
      chips: 1000,
      x: 600,
      y: 135,
      dealer: {
        show: true,
        x: 790,
        y: 275,
      },
      hiddenCards: {
        show: false,
        x: 725,
        y: 200,
      },
      chipBet: {
        flip: false,
        show: false,
        x: 585,
        y: 310,
      }
    },
    {
      id: null,
      current: false,
      avatar: "av",
      name: "Валера",
      chips: 1000,
      x: 1320,
      y: 135,
      dealer: {
        show: true,
        x: 1125,
        y: 270,
      },
      hiddenCards: {
        show: true,
        x: 1195,
        y: 200,
      },
      chipBet: {
        flip: true,
        show: true,
        x: 1240,
        y: 310,
      },
    },
    {
      id: null,
      current: false,
      avatar: "av",
      name: "Europa",
      chips: 1000,
      x: 1620,
      y: 550,
      dealer: {
        show: true,
        x: 1478,
        y: 470,
      },
      hiddenCards: {
        show: true,
        x: 1500,
        y: 530,
      },
      chipBet: {
        show: true,
        flip: true,
        x: 1350,
        y: 510,
      },
    },
    /** Main Player **/
    {
      id: null,
      current: false,
      avatar: "av",
      name: "Smart",
      chips: 1000,
      x: 900,
      y: 820,
      dealer: {
        show: true,
        x: 850,
        y: 680,
      },
      hiddenCards: {
        show: true,
        x: 1025,
        y: 775,
      },
      chipBet: {
        show: true,
        flip: true,
        x: 950,
        y: 666,
      },
    },
    /** Main Player END **/
    {
      id: null,
      current: false,
      avatar: "av",
      name: "Mystericon",
      chips: 1000,
      x: 290,
      y: 550,
      dealer: {
        show: true,
        x: 460,
        y: 500,
      },
      hiddenCards: {
        show: true,
        x: 410,
        y: 600,
      },
      chipBet: {
        show: true,
        flip: false,
        x: 472,
        y: 560,
      },
    }
  ]
};


exports.fivePlayersLobby = {
  sits: [
    {
      id: null,
      avatar: "av",
      name: "Vlad",
      chips: 1000,
      x: -200,
      y: -280,
    },
    {
      id: null,
      avatar: "av",
      name: "Валера",
      chips: 1000,
      x: 200,
      y: -280,
    },
    {
      id: null,
      avatar: "av",
      name: "Europa",
      chips: 1000,
      x: 480,
      y: -50,
    },
    {
      id: null,
      avatar: "av",
      name: "Smart",
      chips: 1000,
      x: 0,
      y: 220,
    },
    {
      id: null,
      avatar: "av",
      chips: 1000,
      name: "Mystericon",
      x: -480,
      y: -50,
    }
  ]
};
