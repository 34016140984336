import {Scene} from 'phaser';
import Button from './class/button';
//import store from '@/store';
import ButtonText from './class/buttonText';
import PreMenuBot from './menu/PreMenuBot';
import Overlay from './Modules/Overlay';
import eventBus from '@/plugins/event-bus';
import Counter from "./class/Counter";

export default class PlayScene extends Scene {
  constructor() {
    super({key: 'PreScene'})
  }

  preload() {
    this.soundDoor = this.sound.add('soundDoor', {
      mute: !this.game.config.sound,
      volume: 1,
      rate: 1,
      detune: 0,
      seek: 0,
      loop: false,
      delay: 0
    });


    /*
    this.music = this.sound.add('music', {
      mute: !this.game.config.music,
      volume: 0.5,
      rate: 1,
      detune: 0,
      seek: 0,
      loop: true,
      delay: 0
    });
     */
  }

  wake() {
    //console.log('wake pre scene')
    this.scene.run('PreScene');
    this.scene.get('PreScene').soundDoor.play();
  }

  create() {

    setTimeout(async () => {
        //this.music.play();
    }, 1200);
    let _self = this;
    eventBus.on('set:sound', async function (data) {
      _self.soundDoor.setMute(!data);
    });

    eventBus.on('set:music', async function (data) {
      //_self.music.setMute(!data);
      if (data) {
        //_self.music.resume();
      } else {
        //_self.music.pause();
      }
    });
    this.add.image(this.game.config.width / 2, this.game.config.height / 2, 'bgStars').setOrigin(0.5, 0.5)

    this.points = [];
    this.stars = this.add.group();
    this.maxDepth = 16;

    for (let i = 0; i < 32; i++) {
      this.points.push({
        x: Phaser.Math.Between(-25, 25),
        y: Phaser.Math.Between(-25, 25),
        z: Phaser.Math.Between(1, this.maxDepth)
      });
    }

    //this.add.image(this.game.config.width / 2, this.game.config.height / 2 - 400, 'kotik').setOrigin(0.5, 0.5);
    this.add.image(this.game.config.width / 2, 200, 'titleBlue').setOrigin(0.5, 0.5);

    //this.btnCredits = this.add.image(this.game.config.width / 2, this.game.config.height / 2 + 400, 'iconFrontChip').setOrigin(0.5, 0.5).setInteractive();
    //frontChip

    this.btnCredits = new Button(this, this.game.config.width / 2, this.game.config.height / 2 + 350, 'frontChip', () => {
      this.scene.switch('CreditsScene');
    }, 'front_chip_hover.png', 'front_chip.png', 'front_chip_hover.png').setOrigin(0.5, 0.5);

    this.animCredits = this.tweens.add({
      targets: [this.btnCredits],
      alpha: {from: 1, to: 0.5},
      angle: 180,
      ease: 'Sine.easeInOut',       // 'Cubic', 'Elastic', 'Bounce', 'Back'
      duration: 800,
      yoyo: true
    });


    this.btnPlayCrypto = new ButtonText(this, this.game.config.width / 2, this.game.config.height / 2 - 40, 'btnPlay', () => {
      this.animCredits.resume();
      this.menuBot.tweenPanel.resume();
      this.scene.sleep('PreScene');
      this.scene.switch('GameMenu');
      //this.scene.get('PreScene').soundDoor.play();
    }, 'btnPlayHover.png', 'btnPlay.png', 'btnPlayHover.png', {
      text: 'PLAY FOR CRYPTO',
      color: '#00e7ff',
      size: 35
    });

    this.btnPlayFree = new ButtonText(this, this.game.config.width / 2, this.game.config.height / 2 + 130, 'btnPlay', () => {
      //this.scene.start('GameMenuFree', {});
      //this.scene.switch('GameMenuFree');
    }, 'btnPlayHover.png', 'btnPlay.png', 'btnPlayHover.png', {
      text: 'PLAY FOR FREE',
      color: '#00e7ff',
      size: 35
    }).setAlpha(0.4);

    this.menuBot = new PreMenuBot(this, this.game.config.width / 2, this.game.config.height);

    this.userCounter = new Counter(this, 100, this.game.config.height / 2 + 200);

    this.overlay = new Overlay(this);

    /*
    this.btnCredits.on('pointerdown', function () {
      this.scene.switch('CreditsScene');
    }, this);
     */






    this.cameras.main.fadeIn(1000);
    //this.cameras.main.fadeOut(1000);

    this.sys.events.on('wake', this.wake, this);

  }

  update() {

    /*
        this.stars.clear(true, true);
        for (let i = 0; i < this.points.length; i++) {
          let point = this.points[i];

          point.z -= 0.2;

          if (point.z <= 0) {
            point.x = Phaser.Math.Between(-25, 25);
            point.y = Phaser.Math.Between(-25, 25);
            point.z = this.maxDepth;
          }

          let px = point.x * (128 / point.z) + (this.game.config.width * 0.5);
          let py = point.y * (128 / point.z) + (this.game.config.height * 0.5);

          let circle = new Phaser.Geom.Circle(
            px,
            py,
            (1 - point.z / 32) * 2
          );

          let graphics = this.add.graphics({fillStyle: {color: 0xeaa953}});
          graphics.setAlpha((1 - point.z / 32));
          graphics.fillCircleShape(circle);
          this.stars.add(graphics);
        }
    */
  }


}
