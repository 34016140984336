import {ninePlayers, fivePlayers} from '@/configGames';
import PlayerCards from './PlayerCards';
import HiddenCards from './HiddenCards';
import PlayerBet from '../class/PlayerBet';
import PlayerTime from '../class/PlayerTime';
import PlusChips from './PlusChips';
import axios from "axios";
import eventBus from '@/plugins/event-bus';

export default class Player extends Phaser.GameObjects.Container {
  constructor(scene, x, y, data = {}) {
    super(scene, x, y);
    scene.add.existing(this);
    this.setVisible(false);
    let _self = this;
    this.smileTimer = null;

    let hCards = [], pCards = [], pBet = [], pTime = [], plusChips = [];

    /** 5 **/

    const plusChips5 = [
      {x: 120, y: 120},
      {x: -120, y: 120},
      {x: -200, y: 72},
      {x: 120, y: -120},
      {x: 200, y: 72}
    ];

    const plusChips9 = [
      {x: 120, y: 160},
      {x: -120, y: 160},
      {x: -180, y: 125},
      {x: -180, y: 100},
      {x: -140, y: -140},
      {x: 0, y: -180},
      {x: 0, y: -180},
      {x: 180, y: 0},
      {x: 160, y: 100}
    ];

    const hCards5 = [
      {x: 120, y: 70},
      {x: -120, y: 70},
      {x: -120, y: 72},
      {x: 120, y: -50},
      {x: 120, y: 72}
    ];

    const hCards9 = [
      {x: 120, y: 70},
      {x: -120, y: 70},
      {x: -120, y: 40},
      {x: -120, y: 70},
      {x: -120, y: -60},
      {x: -120, y: -65},
      {x: -110, y: -120},
      {x: 120, y: -50},
      {x: 120, y: 40}
    ];

    const pCards5 = [
      {x: 160, y: 10},
      {x: -160, y: 10},
      {x: 160, y: 10},
      {x: 160, y: 10},
      {x: -160, y: 10}
    ];

    const pCards9 = [
      {x: 160, y: 10},
      {x: -160, y: 10},
      {x: 160, y: 10},
      {x: 160, y: 10},
      {x: 160, y: 10},

      {x: 160, y: 10},
      {x: -160, y: 10},
      {x: -160, y: 10},
      {x: -160, y: 10}
    ];

    const pBet5 = [
      {x: -15, y: 175},
      {x: -70, y: 175},
      {x: -260, y: -40},
      {x: 50, y: -150},
      {x: 180, y: 10}
    ];

    const pBet9 = [
      {x: -15, y: 175},
      {x: -70, y: 175},
      {x: -200, y: 150},
      {x: -280, y: 0},
      {x: -200, y: -170},
      {x: 0, y: -180},
      {x: 25, y: -180},
      {x: 180, y: 0},
      {x: 180, y: 100}
    ];

    const pTime5 = [
      {x: -100, y: 0},
      {x: 100, y: 0},
      {x: -100, y: 0},
      {x: -100, y: 0},
      {x: 100, y: 0},
    ];


    /** 9 **/

    const pTime9 = [
      {x: -100, y: 0},
      {x: 100, y: 0},
      {x: -100, y: 0},
      {x: -100, y: 0},
      {x: -100, y: 0},
      {x: -100, y: 0},
      {x: 100, y: 0},
      {x: 100, y: 0},
      {x: 100, y: 0},
    ];

    if (data.maxPlayers === 5) {
      hCards = hCards5;
      pCards = pCards5;
      pBet = pBet5;
      pTime = pTime5;
      plusChips = plusChips5;
    } else {
      hCards = hCards9;
      pCards = pCards9;
      pBet = pBet9;
      pTime = pTime9;
      plusChips = plusChips9;
    }

    this.activePlayer = scene.add.image(0, -89, 'avPlayActive')
      .setOrigin(0.5, 0.5).setScale(0.59).setVisible(false);

    this.winPlayer = scene.add.image(1, -88, 'avWin')
      .setOrigin(0.5, 0.5).setScale(0.57).setVisible(false);


    this.stackSize = scene.add.text(10, 96, Math.floor(data.stackSize) || 0, {
      fontFamily: 'electrolize',
      fontSize: 24,
      color: '#ffffff'
    }).setOrigin(0.5, 0.5);

    this.playerName = scene.add.text(0, -87, data.name, {
      fontFamily: 'electrolize',
      fontSize: 21,
      color: '#FFFFFF',
      strokeThickness: 1, stroke: '#030a12',
    }).setOrigin(0.5, 0.5);

    this.playerActionMsg = scene.add.text(0, 65, 'HELLO!', {
      fontFamily: 'electrolize',
      fontSize: 23,
      color: '#e9a724',
      strokeThickness: 2, stroke: '#333333',
    }).setOrigin(0.5, 0.5).setVisible(true);

    this.cards = new PlayerCards(scene, pCards[data.index].x, pCards[data.index].y);
    this.hiddenCards = new HiddenCards(scene, hCards[data.index].x, hCards[data.index].y);
    this.plusChips = new PlusChips(scene, plusChips[data.index].x, plusChips[data.index].y);

    this.timer = new PlayerTime(scene, pTime[data.index].x, pTime[data.index].y);
    this.avatar = scene.add.image(2, -5, 'avatars', 'male')
      .setOrigin(0.5, 0.5).setScale(0.52)//.setVisible(!this.playerSmile.visible);
    this.playerSmile = scene.add.image(2, 0, 'smiles', '0')
      .setOrigin(0.5, 0.5).setScale(0.59).setVisible(false);

    this.bet = new PlayerBet(scene, pBet[data.index].x, pBet[data.index].y, 0);

    this.add([
      scene.add.image(0, 0, 'avBack').setOrigin(0.5, 0.5).setScale(0.58),
      this.activePlayer,
      this.avatar,
      this.playerSmile,
      scene.add.image(-28, 96, 'chipBlack',).setOrigin(1, 0.5).setScale(0.74),
      this.stackSize,
      this.playerActionMsg,
      this.hiddenCards,
      this.cards,
      this.timer,
      this.winPlayer,
      this.playerName,
      this.bet,
      this.plusChips,
    ]);

    this.plusChipsAnim = scene.tweens.add({
      targets: this.plusChips,
      x: 0,
      y: 96,
      alpha: {from: 1, to: 0},
      ease: 'Sine.easeInOut',
      duration: 1500,
      paused: true,
    });

    this.tweenSmile = scene.tweens.add({
      targets: [this.playerSmile],
      //y: '0',
      scale: {from: 0, to: 0.58},
      //alpha: {from: 0.5, to: 1},
      ease: 'Elastic',
      duration: 1500,
      paused: true,
      onStart: async function () {
        //_self.setVisible(true);
      },
    });

    this.tweenSmileHide = scene.tweens.add({
      targets: [this.playerSmile],
      //y: '0',
      scale: {from: 0.60, to: 0},
      //alpha: {from: 1, to: 0},
      ease: 'Elastic',
      duration: 800,
      paused: true,
      onComplete: async function () {
        _self.playerSmile.setVisible(false);
      },
    });

  }

  setSmile(frame) {
    this.playerSmile.setFrame(frame);
    this.playerSmile.setVisible(true);
    clearTimeout(this.smileTimer);
    //this.tweenSmile.play();
    let _self = this;
    this.smileTimer = setTimeout(async () => {
      this.tweenSmileHide.play();
      //this.playerSmile.setVisible(false);
    }, 6000);
  }

  animGetInGame(amount) {
    this.plusChips.show();
    this.plusChips.setAmount(Math.floor(amount));
    this.plusChipsAnim.play(true);
  }

  setStakeSize(amount) {
    this.stackSize.text = amount;
  }

  current(state = false) {
    this.activePlayer.setVisible(state);
  }

  setActionMsg(action = '') {
    this.playerActionMsg.text = action;
  }

  setDataPlayer(playerData) {
    this.setVisible(true);
    this.playerName.text = playerData.name;
    this.bet.setAmount(playerData.bet);
    this.cards.set(playerData.holeCards, playerData.hand);
    this.stackSize.text = Math.floor(playerData.stackSize);
    if (playerData.sex) {
      this.avatar.setFrame(playerData.sex)
    }


    if (playerData.left) {
      this.playerActionMsg.text = 'LEFT';
    }
  }

  setPrivateData(privateData) {
    if (privateData) {
      if (privateData.hand) {
        //this.cards.set(privateData.holeCards, privateData.hand);
      }
    }

  }

  showCards() {
    this.cards.setVisible(true);
    this.hiddenCards.setVisible(false);
  }

  hideCards() {
    this.cards.setVisible(false);
    this.hiddenCards.setVisible(true);
  }


}
