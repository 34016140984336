import Button from "../class/button";
import ButtonText from "../class/buttonText";
import store from '@/store';

export default class AccountSettings extends Phaser.GameObjects.Container {
  constructor(scene, x, y) {
    super(scene, x, y);
    scene.add.existing(this);
    this.setVisible(false);

    this.btnFormClose = new Button(scene, 568, -287, 'btnFormClose', () => {
      this.setVisible(false);
    }, 'close_hover', 'close', 'close_hover').setOrigin(0.5, 0.5);

    this.formTitle = scene.add.text(0, -450, 'ACCOUNT SETTINGS', {
      fontFamily: 'electrolize',
      fontSize: 30,
      fill: '#b1d8f1'
    }).setOrigin(0.5, 0.5);

      this.add([
        scene.add.image(0, 0, 'overlay').setOrigin(0.5, 0.5).setInteractive(),
        this.btnFormClose,
        scene.add.image(0, 0, 'bgFormItems').setOrigin(0.5, 0.5),
        this.formTitle,
      ]);

    this.tweenClose = scene.tweens.add({
      targets: [this.btnFormClose],
      y: '-=100',
      x: '+=100',
      ease: 'Cubic',
      duration: 1000,
      paused: true,
    });

    this.tweenFormTitle = scene.tweens.add({
      targets: [this.formTitle],
      alpha: {from: 0, to: 1},
      ease: 'Cubic',
      duration: 1600,
      paused: true,
    });

    this.setScale(0.8);
    this.tweenItmContainer = scene.tweens.add({
      targets: [this],
      scaleX: 1,
      scaleY: 1,
      ease: 'Power1',
      duration: 300,
      paused: true,
    });

  }


  async getFreeChips(amount) {

  }

  show() {
    this.setVisible(true);
    this.tweenItmContainer.resume();
    this.tweenClose.resume();
    this.tweenFormTitle.resume();
  }


}
