import {Scene} from 'phaser'
import Button from '../class/button';
import LobbyTopPanel from '../Modules/Lobby/LobbyTopPanel';
import LobbyBotPanel from '../Modules/Lobby/LobbyBotPanel';
import TablesContainer from '../Modules/Lobby/TablesContainer';
import Overlay from '../Modules/Overlay';
import eventBus from '@/plugins/event-bus';
import axios from 'axios';
import {network} from '@/config';
import store from '@/store';

export default class LobbyScene extends Scene {
  constructor() {
    super({key: 'LobbyScene'})
  }

  preload() {

  }

  async wake() {
    this.scene.run('LobbyScene');
    //this.lobbyStore = await store.dispatch('games/getLobby');
    //this.lobbyStore = (await axios.get(network.API + '/api/lobby')).data;
    //await this.lobbyUpdate(this.lobbyStore);
    //await this.lobbyInit();
  }

  /** CREATE **/
  async lobbyUpdate(data) {
    //this.tablesContainer.x = this.game.config.width / 2;
    this.xMin = 0 - (data.length - 1) * 1100;
    this.countTables = data.length;

    for (let i = 0; i < this.tablesContainer.list.length; i++) {
      this.tablesContainer.list[i].setVisible(false);
    }


    for (let z = 0; z < this.tablesContainer.list.length; z++) {
      for (let i = 0; i < data.length; i++) {
        if(this.tablesContainer.list[z].data.tid === data[i].tid) {
          this.tablesContainer.list[z].setVisible(true);
          this.tablesContainer.list[z].x = i * 1220;
          this.tablesContainer.list[z].setCountPlayers(data[i].players.length, data[i].maxPlayers);

          if (data[i].players.length < data[i].maxPlayers) {
            this.tablesContainer.list[z].btnPlay.setVisible(true)
          } else {
            this.tablesContainer.list[z].btnPlay.setVisible(false)
          }


          for (let j = 0; j < data[i].maxPlayers; j++) {
            this.tablesContainer.list[z].players.list[j].hide();
          }
          for (let j = 0; j < data[i].players.length; j++) {
            this.tablesContainer.list[z].players.list[j].setPlayer(data[i].players[j]);
          }
        }
      }
    }

  }

  async controls(lobbyStore) {
    let _self = this;
    this.countTables = lobbyStore.length;
    this.input.on('drag', function (pointer, gameObject, dragX, dragY) {
      _self.tablesContainer.x = dragX
    });
    this.xMin = 0 - (this.countTables - 1) * 1100;
    const xMax = 980;
    this.input.on('wheel', function (pointer, gameObjects, deltaX, deltaY, deltaZ) {
      _self.tablesContainer.x += deltaY * 1.24;
      if (_self.tablesContainer.x < this.xMin) {
        _self.tablesContainer.x = this.xMin
      }
      if (_self.tablesContainer.x > xMax) {
        _self.tablesContainer.x = xMax
      }
    });
    this.effectLeft = this.add.image(this.game.config.width - 255, this.game.config.height / 2, 'backEffect').setOrigin(0, 0.5).setInteractive();
    this.effectRight = this.add.image(0, this.game.config.height / 2, 'backEffect').setOrigin(0, 0.5).setFlipX(true).setInteractive();

    this.arrLeft = new Button(this, 60, this.game.config.height / 2 + 70, 'ui', () => {
      if (this.tablesContainer.x < 100) {
        this.tweens.add({
          targets: this.tablesContainer,
          x: '+=1104',
          ease: 'Sine.easeInOut',
          duration: 400,
        });
      }
    }, 'arr-left.png', 'arr-left.png', 'arr-left.png').setScale(1.5);
    this.arrRight = new Button(this, this.game.config.width - 60, this.game.config.height / 2 + 70, 'ui', () => {
      if (this.tablesContainer.x > this.xMin) {
        this.tweens.add({
          targets: this.tablesContainer,
          x: '-=1104',
          ease: 'Sine.easeInOut',
          duration: 400,
        });
      }
    }, 'arr-right.png', 'arr-right.png', 'arr-right.png').setScale(1.5);
  }

  async create() {
    let _self = this;

    const lobbyStore = await store.dispatch('games/getLobby');
    //const lobbyStore = (await axios.get(network.API + '/api/lobby')).data;
    this.add.image(this.game.config.width / 2, this.game.config.height / 2, 'bgBlue').setOrigin(0.5, 0.5);
    this.tablesContainer = new TablesContainer(this, this.game.config.width / 2, this.game.config.height / 2 + 100, lobbyStore);

    eventBus.on('lobby:update', async function (data) {
      await _self.lobbyUpdate(data);
    });

    this.tablesContainer.setInteractive();
    this.tablesContainer.inputEnabled = true;

    await this.controls(lobbyStore);
    this.topPanel = new LobbyTopPanel(this, 0, -456);
    //this.botPanel = new LobbyBotPanel(this, this.game.config.width / 2, this.game.config.height - 120);
    this.overlay = new Overlay(this);
    this.textLoad = this.add.text(this.game.config.width / 2, this.game.config.height / 2, 'Loading...', {
      fontFamily: 'electrolize',
      fontSize: 64,
      color: '#ffffff'
    }).setOrigin(0.5, 0.5).setVisible(false);
    await this.lobbyUpdate(lobbyStore);
    this.sys.events.on('wake', this.wake, this);
  }

  update() {

  }
}
