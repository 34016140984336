import Button from '../class/button';
import eventBus from '@/plugins/event-bus';

export default class Smiles extends Phaser.GameObjects.Container {
  constructor(scene, x, y) {
    super(scene, x, y);
    scene.add.existing(this);
    this.setVisible(false);
    let _self = this;


    let w = 200;
    let h = 650;
    const octagon = scene.add.polygon(-60, -60, [20, 0, w, 0, w + 20, 15, w + 20, h, w, h + 20, 20, h + 20, 0, h, 0, 15], 0x05182f)
      .setAlpha(1).setOrigin(0.5, 0).setInteractive();
    octagon.setStrokeStyle(1, 0x2d92a9);
    this.add(octagon);

    this.smiles = [];

    for (let i=0; i < 6; i++) {
      let btn = new Button(scene, -110, i * 110, 'smiles', async () => {
        this.setVisible(false);
        eventBus.emit('smile:set', i);
      }, i, i, i).setScale(0.4);
      this.smiles.push(
        btn
      );
    }

    let k=0;
    for (let i=6; i < 12; i++) {
      let btn = new Button(scene, -10, k * 110, 'smiles', async () => {
        this.setVisible(false);
        eventBus.emit('smile:set', i);
      }, i, i, i).setScale(0.4);
      this.smiles.push(
        btn
      );
      k++;
    }

    this.add(this.smiles).setAlpha(0);

    this.tweenSmiles = scene.tweens.add({
      targets: [this],
      //y: '0',
      scale: {from: 0.1, to: 1},
      alpha: {from: 0, to: 1},
      ease: 'Power1',
      duration: 300,
      paused: true,
      onStart: async function () {
        _self.setVisible(true);
      },
    })

  }
}
