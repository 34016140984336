//import Rules from '../panels/panelRules';
import ButtonPlayAction from "../class/ButtonPlayAction";
import Button from "../class/button";
import ButtonText from '../class/buttonText';
import store from '@/store';
import eventBus from '@/plugins/event-bus';

export default class PanelPlayer extends Phaser.GameObjects.Container {
  constructor(scene, x, y, data = {}) {
    super(scene, x, y);
    scene.add.existing(this);
    //this.setVisible(false);

    /*
    scene.game.config.ru = scene.cache.json.get('ru');
    scene.game.config.en = scene.cache.json.get('en');
    scene.game.config.locale = store.getters['app/language'];
     */

    this.status = scene.add.text(150, -60, 'Wait Hands', {fontFamily: 'electrolize', fontSize: 40}).setOrigin(0, 0.5);
    this.btnFold = new ButtonPlayAction(scene, 550, -60, 'btnNormal', async () => {
      await scene.actionFold();
    }, 'btn_press.png', 'btn_normal.png', 'btn_press.png', 'FOLD');

    this.btnCheck = new ButtonPlayAction(scene, 550 + 170, -60, 'btnNormal', async () => {
      await scene.actionCheck();
    }, 'btn_press.png', 'btn_normal.png', 'btn_press.png', 'CHECK');

    this.btnCall = new ButtonPlayAction(scene, 550 + 170 * 2, -60, 'btnNormal', async () => {
      await scene.actionCall();
    }, 'btn_press.png', 'btn_normal.png', 'btn_press.png', 'CALL');

    this.btnRaise = new ButtonPlayAction(scene, 550 + 170 * 3, -60, 'btnNormal', async () => {
      await scene.actionRaise();
    }, 'btn_press.png', 'btn_normal.png', 'btn_press.png', 'RAISE');

    this.btnBet = new ButtonPlayAction(scene, 550 + 170 * 4, -60, 'btnNormal', async () => {
      await scene.actionBet();
    }, 'btn_press.png', 'btn_normal.png', 'btn_press.png', 'BET');

    this.btnAllIn = new ButtonPlayAction(scene, 550 + 170 * 5, -60, 'btnNormal', async () => {
      await scene.actionAllIn();
    }, 'btn_press.png', 'btn_normal.png', 'btn_press.png', 'ALL-IN');


    /*
    let _self = this
    eventBus.on('table:response', async (data) => {
      _self.setAmount('BET ' + data.data.options.maxBuyIn);
    });
     */

    /*
    this.waitCards = scene.add.image(60, -60, 'waitCards', '2').setOrigin(0.5, 0.5).setScale(0.86).setInteractive()
      .on('pointerdown', function () {
        scene.rules.setVisible(true);
      }, this);
     */


    this.btnWaitCards = new Button(scene, 60, -60, 'waitCards', async () => {
      scene.rules.setVisible(true);
    }, '1', '2', '1');


    this.add([
      scene.add.image(scene.game.config.width / 2, -120, 'notification').setOrigin(0.5, 0), //bg bot menu
      this.btnWaitCards,
      this.status,
      this.btnFold,
      this.btnCheck,
      this.btnCall,
      this.btnRaise,
      this.btnBet,
      this.btnAllIn
    ]);
//this.rules = new Rules(scene, 2, 10);

    this.soundDing = scene.sound.add('soundDing', {
      mute: false,
      volume: 1,
      rate: 1,
      detune: 0,
      seek: 0,
      loop: false,
      delay: 0
    });

    /*
    function getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max));
    }
    this.randActions = async function (actions) {
      let randAction = getRandomInt(actions.length);
      //console.log(actions)
      let rndStep = Math.floor(Math.random() * 100);
      setTimeout(async () => {

        if (actions[randAction] === 'fold') {

          if (actions[0] === 'call') {
            await scene.actionCall();
          } else {
            if (actions[0] === 'check') {
              await scene.actionCheck();
            } else {
              await scene.actionFold();
            }
          }
        }

          if (actions[randAction] === 'check') {
            await scene.actionCheck();
          }

          if (actions[randAction] === 'bet') {
            await scene.actionBet();
          }

          if (actions[randAction] === 'call') {
            await scene.actionCall();
          }






          if (actions[randAction] === 'raise') {
            await scene.actionRaise();
          }



          if (actions[randAction] === 'allin') {
            await scene.actionAllIn();
          }





      }, 1000 * (randAction + 1));
    }
     */

  }



  showActions(actions) {
    this.hideActions();
    this.status.text = 'Your Action';

    if (actions.length > 0) {
      this.soundDing.play();
      //this.randActions(actions);
    }

    for (let i = 0; i < actions.length; i++) {
      if (actions[i] === 'fold') {
        this.btnFold.setVisible(true);
      }

      if (actions[i] === 'raise') {
        this.btnRaise.setVisible(true);
      }

      if (actions[i] === 'call') {
        this.btnCall.setVisible(true);
      }

      if (actions[i] === 'check') {
        this.btnCheck.setVisible(true);
      }

      if (actions[i] === 'bet') {
        this.btnBet.setVisible(true);
        this.btnAllIn.setVisible(true);
      }
    }
  }

  hideActions() {
    this.status.text = 'Wait Action';
    this.btnFold.setVisible(false);
    this.btnCheck.setVisible(false);
    this.btnRaise.setVisible(false);
    this.btnCall.setVisible(false);
    this.btnBet.setVisible(false);
    this.btnAllIn.setVisible(false);
  }

  setAmount(text) {
    this.btnBet.setAmount(text);
  }
}
