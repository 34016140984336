import Button from '../class/button';
import store from '@/store';
import copy from 'clipboard-copy';
import eventBus from '@/plugins/event-bus';

export default class Settings extends Phaser.GameObjects.Container {
  constructor(scene, x, y) {
    super(scene, x, y);
    scene.add.existing(this);
    this.setVisible(false);
    let settingsStore = store.getters['app/settings'];
    let accountStore = store.getters['account/account'];
    const appSound = store.getters['app/sound'];
    const appMusic = store.getters['app/music'];


    let name = 'Guest';
    if (!scene.game.config.player) {
      scene.game.config.player = store.getters['account/player'];
      /*
      scene.game.config.player = {
        name: 'Guest'
      }
       */
    }

    this.playerName = scene.game.config.player.name; //settingsStore.name;
    this.timerName = null;

    this.btnClose = new Button(scene, 308, -256, 'btnFormClose', () => {
      this.setVisible(false);
      //document.removeEventListener("keydown", this.evtKey)
    }, 'close_hover', 'close', 'close_hover').setOrigin(0.5, 0.5);


    let offset = -400;


    let swSound = scene.add.image(250, offset + 130, 'swOnOff', appSound ? 'swOn': 'swOff').setInteractive();
    swSound.on('pointerdown', async function () {
      if (swSound.frame.name === 'swOn') {
        await store.dispatch('app/setSound', false);
        scene.game.config.sound = false;
        swSound.setFrame('swOff');
      } else {
        swSound.setFrame('swOn');
        await store.dispatch('app/setSound', true);
        scene.game.config.sound = true;
      }
      eventBus.emit('set:sound', scene.game.config.sound);
      //await store.dispatch('app/setModalProfile', true);
    }, scene);


    let swNotification = scene.add.image(250, offset + 130 * 2, 'swOnOff', appMusic ? 'swOn': 'swOff').setInteractive();
    swNotification.on('pointerdown', async function () {
      if (swNotification.frame.name === 'swOn') {
        swNotification.setFrame('swOff');
        await store.dispatch('app/setMusic', false);
        scene.game.config.music = false;
      } else {
        swNotification.setFrame('swOn');
        await store.dispatch('app/setMusic', true);
        scene.game.config.music = true;
      }
      eventBus.emit('set:music', scene.game.config.music);
    }, scene);

    let reset = scene.add.image(0, 175, 'resetAll').setOrigin(0.5, 0.5).setInteractive();
    let _self = this;
    reset.on('pointerdown', async function () {
      _self.hide();
      eventBus.emit('modal:reset');
    });


    this.add([
      scene.add.image(0, 0, 'overlay').setOrigin(0.5, 0.5).setInteractive(),
      this.btnClose,
      scene.add.image(0, 0, 'bgFormSettings').setOrigin(0.5, 0.5),
      scene.add.text(0, -420, scene.game.config[scene.game.config.locale]['APP']['SETTINGS'], {
        fontFamily: 'electrolize',
        fontSize: 30,
        fill: '#b1d8f1'
      }).setOrigin(0.5, 0.5),

      scene.add.image(0, -60, 'formLine').setOrigin(0.5, 0.5),

      scene.add.image(-400, offset + 130, 'icoSound').setOrigin(0.5, 0.5),
      scene.add.image(-400, offset + 130 * 2, 'icoNotification').setOrigin(0.5, 0.5),
      reset,

      scene.add.text(-300, offset + 130, scene.game.config[scene.game.config.locale]['APP']['SOUND'], {
        fontFamily: 'electrolize',
        fontSize: 28,
        fill: '#2c799f'
      }).setOrigin(0, 0.5),
      scene.add.text(-300, offset + 130 * 2, 'MUSIC', {
        fontFamily: 'electrolize',
        fontSize: 28,
        fill: '#2c799f'
      }).setOrigin(0, 0.5),

      swSound, swNotification,


    ]);

    this.tweenClose = scene.tweens.add({
      targets: [this.btnClose],
      y: '-=100',
      x: '+=100',
      ease: 'Cubic',
      duration: 1000,
      paused: true,
    });

    //console.log('this.visible', this.visible)


  }

  show() {
    this.setVisible(true);
    this.tweenClose.resume();
  }

  hide() {
    this.setVisible(false);
    this.tweenClose.resume();
  }

}
