import {Scene} from 'phaser'
import store from '@/store';
import {network} from '@/config';
import eventBus from '@/plugins/event-bus';

import ru from '@/locales/ru.json';
import en from '@/locales/en.json';

//import Music from '@/game/assets/media/shuffle.mp3'
import soundClick from '@/game/assets/media/click.mp3';
import soundDing from '@/game/assets/media/ding.mp3';
import soundChip from '@/game/assets/media/chip.mp3';
import soundDoor from '@/game/assets/media/doors.mp3';
import soundFold from '@/game/assets/media/fold.mp3';
import soundAllin from '@/game/assets/media/allin.mp3';
import soundWin from '@/game/assets/media/win.mp3';
import soundShuffle from '@/game/assets/media/shuffle.mp3';


import galaxy1 from '@/game/assets/images/galaxy/galaxy1.png';
import iconProfile from '@/game/assets/images/icons/iconProfile.png';

import avBgTop from '@/game/assets/images/av/av_bg_top.png';

import goldCoin from '@/game/assets/images/goldCoin.png';

import bonusTime from '@/game/assets/images/bonus_time.png';
import resetAll from '@/game/assets/atlas/resetAll.png';
import resetAllAtlas from '@/game/assets/atlas/resetAll.json';
import walletGoldIcon from '@/game/assets/images/icons/wallet_gold.png';

import tasovka from '@/game/assets/atlas/tasovka.png';
import tasovkaAtlas from '@/game/assets/atlas/tasovka.json';

import smiles from '@/game/assets/atlas/smiles.png';
import smilesAtlas from '@/game/assets/atlas/smiles.json';

import waitCards from '@/game/assets/atlas/waitCards.png';
import waitCardsAtlas from '@/game/assets/atlas/waitCards.json';

import btnWallet from '@/game/assets/atlas/btnWallet.png';
import btnWalletAtlas from '@/game/assets/atlas/btnWallet.json';

import btnRocket from '@/game/assets/atlas/btnRocket.png';
import btnRocketAtlas from '@/game/assets/atlas/btnRocket.json';

import btnCategory from '@/game/assets/atlas/btnCategory.png';
import btnCategoryAtlas from '@/game/assets/atlas/btnCategory.json';

import btnAv from '@/game/assets/atlas/btnAv.png';
import btnAvAtlas from '@/game/assets/atlas/btnAv.json';

import btnSell from '@/game/assets/atlas/btnSell.png';
import btnSellAtlas from '@/game/assets/atlas/btnSell.json';

import btnBuy from '@/game/assets/atlas/btnBuy.png';
import btnBuyAtlas from '@/game/assets/atlas/btnBuy.json';

import btnExit from '@/game/assets/atlas/btnExit.png';
import btnExitAtlas from '@/game/assets/atlas/btnExit.json';

import btnLock from '@/game/assets/atlas/btnLock.png';
import btnLockAtlas from '@/game/assets/atlas/btnLock.json';

import noMsg from '@/game/assets/images/noMsg.png';

import sthDark from '@/game/assets/images/menu/sth-dark.png'
//import splash from '@/game/assets/images/splash.png'

import bgStars from '@/game/assets/images/bgStars.png'
import overlay from '@/game/assets/images/overlay.png';

import backEffect from '@/game/assets/images/back-effect.png'
import logoTable from '@/game/assets/images/lobby/logoTable.png'
import notification from '@/game/assets/images/notification.png'
import kotik from '@/game/assets/images/kotik.png';
import kotikGold from '@/game/assets/images/kotikGold.png';
import logoChip from '@/game/assets/images/logo.png';

import XBTS from '@/game/assets/images/XBTS.png';
import logoSth from '@/game/assets/images/logo-sth.png';
import chipIcon from '@/game/assets/images/chips/chip.png';
import chipGold1 from '@/game/assets/images/chips/gold-1.png';
import chipGold2 from '@/game/assets/images/chips/gold-2.png';
import chipGold3 from '@/game/assets/images/chips/gold-3.png';
import chipSth from '@/game/assets/images/chips/sth.png';

import dealer from '@/game/assets/images/dealer.png';
import hiddenCards from '@/game/assets/images/room/hidden-cards.png';
import chipBlack from '@/game/assets/images/room/chip-black.png';
import betField from '@/game/assets/images/room/betField.png';
import BankChips from '@/game/assets/images/room/BankChips.png';
import balanceShape from '@/game/assets/images/room/balanceShape.png';

import chipHover from '@/game/assets/images/chipHover.png'
import sth1 from '@/game/assets/images/menu/sth-1.png'
import sth2 from '@/game/assets/images/menu/sth-2.png'
import av from '@/game/assets/images/av.png';
import avPlayActive from '@/game/assets/images/av/av_active.png';
import avBack from '@/game/assets/images/av/av_back.png';
import avWin from '@/game/assets/images/av/av_winner_win.png';

import deck from '@/game/assets/images/room/desk_1.png';
import gold from '@/game/assets/atlas/gold.png';
import goldAtlas from '@/game/assets/atlas/gold.json';

import timerGreen from '@/game/assets/images/room/timer_green.png';
import timerOrange from '@/game/assets/images/room/timer_orange.png';
import timerRed from '@/game/assets/images/room/timer_red.png';

import timer from '@/game/assets/atlas/timer.png';
import timerAtlas from '@/game/assets/atlas/timer.json';

//import oponent from '@/game/assets/images/oponent.png';

import titleGold from '@/game/assets/images/titleGold.png'
import titleBlue from '@/game/assets/images/main_title.png'
import menuBot from '@/game/assets/images/menuBot.png'
import menuTop from '@/game/assets/images/menuTop.png'
import octa from '@/game/assets/images/octa.png'
import iconCorona from '@/game/assets/images/iconCorona.png'
import frontChip from '@/game/assets/atlas/frontChip.png'
import frontChipAtlas from '@/game/assets/atlas/frontChip.json'

/** SETTINGS **/
import bgFormSettings from '@/game/assets/images/formSettings/bgFormSettings.png';
import icoSound from '@/game/assets/images/formSettings/icon_sound.png';
import icoNotification from '@/game/assets/images/formSettings/icon_notification.png';
import icoLang from '@/game/assets/images/formSettings/icon_lang.png';
import icoPlayer from '@/game/assets/images/formSettings/icon_player.png';

import swOnOff from '@/game/assets/atlas/swOnOff.png';
import swOnOffAtlas from '@/game/assets/atlas/swOnOff.json';
import PrevNext from '@/game/assets/atlas/prev_next.png';
import PrevNextAtlas from '@/game/assets/atlas/prev_next.json';
import inputHover from '@/game/assets/images/formSettings/inputHover.png';
import formLine from '@/game/assets/images/formSettings/line.png';

import bgGameGray from '@/game/assets/images/bg/bgGameGray.png'

import sitHover from '@/game/assets/images/lobby/sitHover.png'
import waitHand from '@/game/assets/images/room/wait-hand-blue.png'
import bgMenuRight from '@/game/assets/images/room/bg_menu_right.png';


import avatars from '@/game/assets/atlas/avatars.png'
import avatarsAtlas from '@/game/assets/atlas/avatars.json'

import play from '@/game/assets/atlas/play.png';
import playAtlas from '@/game/assets/atlas/play.json';

import view from '@/game/assets/atlas/view.png';
import viewAtlas from '@/game/assets/atlas/view';

import ui from '@/game/assets/images/ui.png'
import uiAtlas from '@/game/assets/atlas/ui.json'

//import menu from '@/game/assets/images/menu.png'
//import menuAtlas from '@/game/assets/atlas/menu.json'

import tables from '@/game/assets/images/lobby/tables.png'
import tablesAtlas from '@/game/assets/atlas/tables.json'

import tb1Purple from '@/game/assets/images/tables/table_2_blue.png';
import bgBlue from '@/game/assets/images/tables/capet_blue.png';

import chips from '@/game/assets/atlas/chips.png';
import chipsAtlas from '@/game/assets/atlas/chips.json';

import btnPrev from '@/game/assets/images/btn_prev.png'
import btnPrevAtlas from '@/game/assets/atlas/btn_prev.json'

import btnTalk from '@/game/assets/images/room/btnTalk.png'
import btnTalkAtlas from '@/game/assets/atlas/btnTalk.json'

import btnSetting from '@/game/assets/images/btn_setting.png'
import btnSettingAtlas from '@/game/assets/atlas/btn_setting.json'

import btnStart from '@/game/assets/images/btn_start.png'
import btnStartAtlas from '@/game/assets/atlas/btn_start.json'

import settingsBot from '@/game/assets/images/settingBot.png'
import settingsBotAtlas from '@/game/assets/atlas/settingBot.json'

import btnCrypto from '@/game/assets/images/btnCrypto.png'
import btnCryptoAtlas from '@/game/assets/atlas/btnCrypto.json'

import btnFree from '@/game/assets/images/btnFree.png'
import btnFreeAtlas from '@/game/assets/atlas/btnFree.json'

import btnPlay from '@/game/assets/atlas/btnPlay.png'
import btnPlayAtlas from '@/game/assets/atlas/btnPlay.json'

import room from '@/game/assets/images/room/room.png'
import roomAtlas from '@/game/assets/atlas/room.json'

import cards from '@/game/assets/images/room/cards.png'
import cardsAtlas from '@/game/assets/atlas/cards.json'

import cardsMain from '@/game/assets/atlas/cardsMain.png';
import cardsMainAtlas from '@/game/assets/atlas/cardsMain.json';

import message from '@/game/assets/images/message.png'
import messageAtlas from '@/game/assets/atlas/message.json'

import btnStore from '@/game/assets/images/btnStore.png'
import btnStoreAtlas from '@/game/assets/atlas/btnStore.json'

import btnPause from '@/game/assets/images/btnPause.png';
import btnPauseAtlas from '@/game/assets/atlas/btnPause.json';

import sthSprite from '@/game/assets/images/menu/sthSprite.png';
import sthSpriteAtlas from '@/game/assets/atlas/sthSprite.json';

import battleYou from '@/game/assets/images/menu/battle_you.png';
import btnNormal from '@/game/assets/images/menu/btn_normal.png';
import btnNormalAtlas from '@/game/assets/atlas/btn_normal.json';

/** GAME **/
import sb from '@/game/assets/images/room/sb.png';
import bb from '@/game/assets/images/room/bb.png';

/** FORMS **/
import btnSignIn from '@/game/assets/images/form/btn_signin.png';
import btnSignInAtlas from '@/game/assets/atlas/btn_signin.json';
import btnContinue from '@/game/assets/images/form/btn_continue.png';
import btnContinueAtlas from '@/game/assets/atlas/btn_continue.json';
import btnUnlock from '@/game/assets/images/form/btn_unlock.png';
import btnUnlockAtlas from '@/game/assets/atlas/btn_unlock.json';

import formBg from '@/game/assets/images/form/formBg.png';
import pinNum from '@/game/assets/images/form/pin_num.png';
import pinNumAtlas from '@/game/assets/atlas/pin_num.json';
import inputPinHover from '@/game/assets/images/form/input_pin_hover.png';

/** FORM Items **/

import bgFormItems from '@/game/assets/images/formItems/bgFormItems.png';
import btnClose from '@/game/assets/images/formItems/close.png';
import btnCloseAtlas from '@/game/assets/images/formItems/close.json';
import ItmSqBig from '@/game/assets/images/formItems/itmSqBig.png';
import ItmSqBig3000 from '@/game/assets/images/formItems/itmSqBig3000.png';
import ItmSqBig5000 from '@/game/assets/images/formItems/itmSqBig5000.png';
import btnGet from '@/game/assets/atlas/btnGet.png';
import btnGetAtlas from '@/game/assets/atlas/btnGet.json';
import btnTrophy from '@/game/assets/images/menu/trophy.png';
import btnTrophyAtlas from '@/game/assets/atlas/trophy.json';

/** FORM EXIT **/
import formExitBg from '@/game/assets/images/formExit/formExitBg.png';
import btnYesNo from '@/game/assets/atlas/btnYesNo.png';
import btnYesNoAtlas from '@/game/assets/atlas/btnYesNo.json';

import menuItm from '@/game/assets/atlas/menuItm.png';
import menuItmAtlas from '@/game/assets/atlas/menuItm.json';


import li32 from '@/game/assets/images/li32.png';

/** **/
import {Connection, JsonSerializer, CLOSE_EVENT_CODE} from 'ws-client-js';
// JSON serializer for send and receive message
const serializer = new JsonSerializer();
const connection = new Connection(serializer, {
  // websocket endpoint
  url: network.WS,
  reconnect: {
    // 1 seconds
    delay: 2000,
    // 'default' | 'twice'
    // After each attempt, the delay increases based on the type. 'default' does not increase
    delayIncreaseType: 'twice',
    // number of attempts
    attempts: 100,
    // connection closure codes for which reconnect will not work
    skipCloseEventCodes: [CLOSE_EVENT_CODE.NORMAL, CLOSE_EVENT_CODE.NO_STATUS_RESERVED],
  },
  debug: false, // log debug messages
});

export default class BootScene extends Scene {
  constructor() {
    super({key: 'BootScene'})
  }

  preload() {

    //this.load.audio('music', [Music]);
    this.load.audio('soundClick', [soundClick]);
    this.load.audio('soundDing', [soundDing]);
    this.load.audio('soundChip', [soundChip]);
    this.load.audio('soundDoor', [soundDoor]);
    this.load.audio('soundFold', [soundFold]);
    this.load.audio('soundAllin', [soundAllin]);
    this.load.audio('soundWin', [soundWin]);
    this.load.audio('soundShuffle', [soundShuffle]);

    this.load.json('ru', ru);
    this.load.json('en', en);


    this.load.image('galaxy1', galaxy1);
    this.load.image('iconProfile', iconProfile);
    this.load.image('avBgTop', avBgTop);
    this.load.image('sitHover', sitHover);
    this.load.image('sthDark', sthDark);
    //this.load.image('splash', splash);
    this.load.image('bgStars', bgStars);

    this.load.image('overlay', overlay);

    this.load.image('bgFormSettings', bgFormSettings);
    this.load.image('icoSound', icoSound);
    this.load.image('icoNotification', icoNotification);
    this.load.image('icoLang', icoLang);
    this.load.image('icoPlayer', icoPlayer);

    this.load.image('inputHover', inputHover);
    this.load.image('formLine', formLine);


    this.load.atlas('swOnOff', swOnOff, swOnOffAtlas);
    this.load.atlas('PrevNext', PrevNext, PrevNextAtlas);

    this.load.image('chipHover', chipHover);
    this.load.image('chipIcon', chipIcon);
    this.load.image('chipGold1', chipGold1);
    this.load.image('chipGold2', chipGold2);
    this.load.image('chipGold3', chipGold3);
    this.load.image('chipSth', chipSth);

    this.load.image('sth1', sth1);
    this.load.image('sth2', sth2);
    this.load.image('backEffect', backEffect);
    this.load.image('logoTable', logoTable);
    this.load.image('notification', notification);
    this.load.image('waitHand', waitHand);
    this.load.image('bgMenuRight', bgMenuRight);

    this.load.image('av', av);
    this.load.image('avBack', avBack);
    this.load.image('avWin', avWin);
    this.load.image('avPlayActive', avPlayActive);
    this.load.image('deck', deck);
    this.load.atlas('gold', gold, goldAtlas);
    this.load.atlas('waitCards', waitCards, waitCardsAtlas);


    this.load.image('timerGreen', timerGreen);
    this.load.image('timerRed', timerRed);
    this.load.image('timerOrange', timerOrange);
    this.load.atlas('timer', timer, timerAtlas);


    this.load.image('kotik', kotik);
    this.load.image('kotikGold', kotikGold);
    this.load.image('logoChip', logoChip);

    this.load.image('XBTS', XBTS);
    this.load.image('logoSth', logoSth);

    this.load.image('dealer', dealer);
    this.load.image('hiddenCards', hiddenCards);
    this.load.image('chipBlack', chipBlack);
    this.load.image('betField', betField);
    this.load.image('BankChips', BankChips);
    this.load.image('balanceShape', balanceShape);

    this.load.image('titleGold', titleGold);
    this.load.image('titleBlue', titleBlue);
    this.load.image('menuBot', menuBot);
    this.load.image('menuTop', menuTop);
    this.load.image('octa', octa);
    this.load.image('iconCorona', iconCorona);
    this.load.atlas('frontChip', frontChip, frontChipAtlas);
    this.load.image('bgGameGray', bgGameGray);
    this.load.atlas('resetAll', resetAll, resetAllAtlas);
    this.load.image('bonusTime', bonusTime);
    this.load.image('walletGoldIcon', walletGoldIcon);


    this.load.atlas('avatars', avatars, avatarsAtlas);
    this.load.atlas('ui', ui, uiAtlas);
    this.load.atlas('smiles', smiles, smilesAtlas);


    this.load.atlas('tables', tables, tablesAtlas);
    this.load.atlas('chips', chips, chipsAtlas);
    this.load.atlas('btnPrev', btnPrev, btnPrevAtlas);
    this.load.atlas('btnCategory', btnCategory, btnCategoryAtlas);
    this.load.atlas('room', room, roomAtlas);
    this.load.atlas('btnTalk', btnTalk, btnTalkAtlas);
    this.load.atlas('btnSetting', btnSetting, btnSettingAtlas);
    this.load.atlas('btnStart', btnStart, btnStartAtlas);

    //this.load.atlas('cards', cards, cardsAtlas);
    this.load.atlas('cards', cardsMain, cardsMainAtlas);

    this.load.atlas('settingsBot', settingsBot, settingsBotAtlas)
    this.load.atlas('message', message, messageAtlas)
    this.load.atlas('btnStore', btnStore, btnStoreAtlas)
    this.load.atlas('btnPause', btnPause, btnPauseAtlas);

    this.load.atlas('btnCrypto', btnCrypto, btnCryptoAtlas);
    this.load.atlas('btnFree', btnFree, btnFreeAtlas);
    this.load.atlas('btnPlay', btnPlay, btnPlayAtlas);

    this.load.atlas('sthSprite', sthSprite, sthSpriteAtlas);
    this.load.atlas('btnWallet', btnWallet, btnWalletAtlas);
    this.load.atlas('btnRocket', btnRocket, btnRocketAtlas);

    this.load.atlas('btnExit', btnExit, btnExitAtlas);
    this.load.atlas('btnLock', btnLock, btnLockAtlas);
    this.load.atlas('btnSell', btnSell, btnSellAtlas);
    this.load.atlas('btnBuy', btnBuy, btnBuyAtlas);


    /** LOAD FORMS **/
    this.load.atlas('btnSignIn', btnSignIn, btnSignInAtlas);
    this.load.atlas('btnContinue', btnContinue, btnContinueAtlas);
    this.load.atlas('btnUnlock', btnUnlock, btnUnlockAtlas);
    this.load.image('formBg', formBg);
    this.load.atlas('pinNum', pinNum, pinNumAtlas);
    this.load.image('inputPinHover', inputPinHover);
    this.load.image('battleYou', battleYou);
    this.load.atlas('btnNormal', btnNormal, btnNormalAtlas);
    this.load.atlas('btnAv', btnAv, btnAvAtlas);

    this.load.image('sb', sb);
    this.load.image('bb', bb);

    /** LOAD FORM Items **/
    this.load.image('bgFormItems', bgFormItems);
    this.load.atlas('btnFormClose', btnClose, btnCloseAtlas);
    this.load.atlas('btnGet', btnGet, btnGetAtlas);

    this.load.atlas('btnTrophy', btnTrophy, btnTrophyAtlas);

    this.load.image('ItmSqBig1000', ItmSqBig);
    this.load.image('ItmSqBig3000', ItmSqBig3000);
    this.load.image('ItmSqBig5000', ItmSqBig5000);
    this.load.image('tb1Purple', tb1Purple);
    this.load.image('bgBlue', bgBlue);
    this.load.atlas('play', play, playAtlas);
    this.load.atlas('view', view, viewAtlas);

    /** FORM EXIT **/
    this.load.atlas('btnYesNo', btnYesNo, btnYesNoAtlas);
    this.load.image('formExitBg', formExitBg);
    this.load.atlas('menuItm', menuItm, menuItmAtlas);

    this.load.image('noMsg', noMsg);
    this.load.image('goldCoin', goldCoin);
    this.load.image('li32', li32);


    this.load.atlas('tasovka', tasovka, tasovkaAtlas);



  }

  async create() {

    this.game.config.ru = this.cache.json.get('ru');
    this.game.config.en = this.cache.json.get('en');
    this.game.config.locale = store.getters['app/language'] || 'en';
    this.game.config.isElectron = process.env.IS_ELECTRON;

    let account = store.getters['account/account'];
    connection.connect();
    connection.onOpen(() => {
      connection.send({op: 'get-lobby', userId: account.userId})
    });

    connection.onMessage(async (msg) => {
      if (msg.op === 'lobby-response') {
        eventBus.emit('lobby:update', msg.data)
      }

      if (msg.op === 'app:counter') {
        store.dispatch('app/setCounter', msg);
        eventBus.emit('app:counter:update', msg)
      }

      /** Добавить по userId**/
      if (msg.op === 'player:update') {
        const account = store.getters['account/account'];
        if (account.userId === msg.player.userId) {
          await store.dispatch('account/setSthBalance', msg.player.sth);
          await store.dispatch('account/setPlayer', msg.player);
          await store.dispatch('wallet/fetchTransactions');
          eventBus.emit('player:update', msg.player);
          eventBus.emit('update:balance', msg.player);
        }
      }

      if (msg.op === 'sth:block:new') {
        eventBus.emit('sth:block:new', msg);
      }

      if (msg.op === 'getingame') {
        const myTable = store.getters['games/table'];
        if (myTable.options.idx === msg.idx) {
          eventBus.emit('anim:getingame', msg);
        }
      }

      if (msg.op === 'table:response') {
        const myTable = store.getters['games/table'];
        const account = store.getters['account/account'];
        if (myTable.tid === msg.data.tid) {

          let hide = true;
          for (let i = 0; i < msg.data.players.length; i++) {
            if (msg.data.players[i]) {
              if (msg.data.players[i].id === account.userId) {
                hide = false;
                break;
              }
            }
          }

          if (hide) {
            await eventBus.emit('hide:getingame');
          }


          await store.dispatch('games/tableUpdate', msg.data);
          await eventBus.emit('table:response', msg);
        }
      }

      if (msg.op === 'chat-msg') {
        const myTable = store.getters['games/table'];
        if (myTable.tid === msg.data.tid) {
          await eventBus.emit('chat-msg:response', msg.data);
        }
      }

      if (msg.op === 'show:smile') {
        const myTable = store.getters['games/table'];
        if (myTable.tid === msg.tid) {
          await eventBus.emit('show:smile', msg);
        }
      }

      if (msg.op === 'chat:log') {
        const myTable = store.getters['games/table'];
        if (myTable.tid === msg.tid) {
          eventBus.emit('chat-log:response', msg);
        }
      }

    });

    /** EVENTS **/
    eventBus.on('table:sit', async (data) => {
      await store.dispatch('games/tableSit', {tid: data.tid, idx: data.idx, userId: account.userId});
      await this.scene.switch('GameScene');
    });

    eventBus.on('player:login', async (data) => {
      connection.send({op: 'get-lobby', userId: data.userId})
    });

    eventBus.on('chat:msg', async (data) => {
      connection.send(data)
    });

    eventBus.on('send:smile', async (data) => {
      connection.send(data)
    });


    const pinCodeHashAES = store.getters['app/pinAES'];
    /*
    if (!pinCodeHashAES) {
      this.scene.start('PanelSetPin');
    } else {
      this.game.config.player = store.getters['account/player'];
      this.scene.start('LoginScene');
    }
     */
    this.game.config.player = store.getters['account/player'];
    this.game.config.sound = store.getters['app/sound'];
    this.game.config.music = store.getters['app/music'];

    eventBus.on('scene:open', async (sceneName = 'PreScene') => {
      //this.scene.start(sceneName);
    });


    this.scene.start('LoginScene');

    //this.scene.start('PreScene');
    //this.scene.start('CreditsScene');
    //this.scene.start('ChipsScene')
    //this.scene.start('GameScene')
    //this.scene.start('GameMenu');
    //this.scene.start('LobbyScene');
  }
}
