import {Scene} from 'phaser'

const GAME_HEIGHT = 1080, GAME_WIDTH = 1920;

export default class CreditsScene extends Scene {
  constructor() {
    super({key: 'CreditsScene'})
  }

  preload() {
    this.plugins.get('rexwebfontloaderplugin').addToScene(this);
    this.load.rexWebFont({
      custom: {
        families: ['electrolize'],
      }
    });
  }


  addBtn(x,y, texture, frameMain = 0, frameHover = 1) {
    let btnHover = this.add.image(x, y, texture, frameHover).setOrigin(0, 0.5).setVisible(false)
    let btn = this.add.image(x, y, texture, frameMain).setOrigin(0, 0.5).setInteractive()

    btn.on('pointerover', function () {
      btnHover.setVisible(true)
    }, this);

    btn.on('pointerout', function () {
      btnHover.setVisible(false)
    }, this);

    return btn
  }

  create() {
    console.log('CreditsScene');

    this.points = [];
    this.stars = this.add.group();
    this.maxDepth = 16;

    for (let i = 0; i < 18; i++) {
      this.points.push({
        x: Phaser.Math.Between(-25, 25),
        y: Phaser.Math.Between(-25, 25),
        z: Phaser.Math.Between(1, this.maxDepth)
      });
    }

    this.add.image(GAME_WIDTH / 2, GAME_HEIGHT / 2, 'bgStars').setOrigin(0.5, 0.5);
    let menuTop = this.add.image(GAME_WIDTH / 2, -50, 'menuTop').setOrigin(0.5, 0);
    let menuBot = this.add.image(GAME_WIDTH / 2, GAME_HEIGHT + 150, 'menuTop').setOrigin(0.5, 1).setFlipY(true);

    let about = [];


    for (let i=1; i < 13; i++) {
      this.add.image(GAME_WIDTH / 2 - 645, 220 + i * 55, 'li32').setOrigin(0, 0.5)
      about.push(
        this.add.text(GAME_WIDTH / 2 - 600, 220 + i * 55, this.game.config[this.game.config.locale]['GAME']['HELP_' + i], {
        fontFamily: 'electrolize',
        fontSize: 32
      }).setOrigin(0, 0.5)
      );
    }









    let btnBack = (this.addBtn(50, 80, 'btnPrev', 0, 1)).setInteractive();
    //let btnBack = this.add.image(20, GAME_HEIGHT / 2 - 200, 'ui', 'back.png').setOrigin(0, 0.5).setInteractive();
    btnBack.on('pointerdown', function () {
      this.scene.switch('PreScene');
    }, this);

    this.add.image(GAME_WIDTH - 120, 88, 'kotikGold').setOrigin(0.5, 0.5).setScale(0.7);
    //this.add.image(130, GAME_HEIGHT - 18, 'logoChip').setOrigin(0.5, 1);
    //this.add.image(GAME_WIDTH - 350, GAME_HEIGHT / 2, 'XBTS').setOrigin(0.5, 0.5).setScale(0.8);
    //this.add.image(350, GAME_HEIGHT / 2, 'logoSth').setOrigin(0.5, 0.5).setScale(0.8);
    //this.add.image(GAME_WIDTH - 350, GAME_HEIGHT / 2 , 'coinFront').setOrigin(0.5, 0.5).setScale(0.8);

    //this.add.image(GAME_WIDTH - 255, GAME_HEIGHT / 2, 'backEffect').setOrigin(0, 0.5);
    //this.add.image(0, GAME_HEIGHT / 2, 'backEffect').setOrigin(0, 0.5).setFlipX(true);

    let gameTitle = this.add.text(GAME_WIDTH / 2, 70, 'SMARTHOLDEM CRYPTO GAMES ENGINE', {
      fontFamily: 'electrolize',
      fontSize: 48
    }).setOrigin(0.5, 0.5);

  }

  update() {
    //this.til.tilePositionY +=1;
    //btnStart.angle += 2
/*
    this.stars.clear(true, true);
    for (let i = 0; i < this.points.length; i++) {
      let point = this.points[i];

      point.z -= 0.2;

      if (point.z <= 0) {
        point.x = Phaser.Math.Between(-25, 25);
        point.y = Phaser.Math.Between(-25, 25);
        point.z = this.maxDepth;
      }

      let px = point.x * (128 / point.z) + (this.game.config.width * 0.5);
      let py = point.y * (128 / point.z) + (this.game.config.height * 0.5);

      let circle = new Phaser.Geom.Circle(
        px,
        py,
        (1 - point.z / 32) * 2
      );

      let graphics = this.add.graphics({fillStyle: {color: 0xffffff}});
      graphics.setAlpha((1 - point.z / 32));
      graphics.fillCircleShape(circle);
      this.stars.add(graphics);
    }
 */
  }
}
