import eventBus from '@/plugins/event-bus';
import Button from '../class/button';
import LobbyPlayers from '../Modules/Lobby/LobbyPlayers';
import store from '@/store';

export default class LobbyTable extends Phaser.GameObjects.Container {
  constructor(scene, x, y, data) {
    super(scene, x, y);
    scene.add.existing(this);

    this.buyIn = {
      min: data.minBuyIn,
      max: data.maxBuyIn,
    };

    this.translate = scene.game.config[scene.game.config.locale];
    this.tableDesign = scene.add.image(0, 0, 'tb1Purple').setScale(0.8).setOrigin(0.5, 0.5).setInteractive();
    this.data = data;

    this.countPlayers = scene.add.text(0, -220, scene.game.config[scene.game.config.locale]['GAME']['PLAYERS'] + ' ' + data.players.length + '/' + data.maxPlayers, {
      fontFamily: 'electrolize',
      fontSize: 30
    }).setOrigin(0.5, 0.5).setAlpha(0.3).setName('countPlayers');

    this.BuyIn = scene.add.text(0, -120, 'BUY-IN ' + this.amountFormat(data.minBuyIn) + '/' + this.amountFormat(data.maxBuyIn), {
      fontFamily: 'electrolize',
      fontSize: 32
    }).setOrigin(0.5, 0.5).setAlpha(0.3).setName('buyIn');

    this.bet = scene.add.text(0, 50, scene.game.config[scene.game.config.locale]['GAME']['BET'] + ' ' + data.smallBlind + '/' + data.bigBlind, {
      fontFamily: 'electrolize',
      fontSize: 36
    }).setOrigin(0.5, 0.5).setAlpha(0.3).setName('blinds');

    this.btnPlay = new Button(scene, -240, -30, 'play', async () => {
      //scene.scene.get('PreScene').music.pause();
      scene.topPanel.tweenPanel.resume();
      eventBus.emit('overlay:show');
      scene.textLoad.setVisible(true);

      const player = store.getters['account/player'];
      let avgBuyIn = Math.floor((this.buyIn.min + this.buyIn.max) / 2);
      if (player.chips.real < avgBuyIn) {
        avgBuyIn = this.buyIn.min;
      }

      if (player.chips.real >= avgBuyIn) {
        this.btnPlay.setVisible(false);
        await store.dispatch('games/tableSit', {
          tid: data.tid,
          idx: data.idx,
          userId: player.userId,
          stack: avgBuyIn,
          pin: scene.game.config.pin,
        });
        await scene.scene.switch('GameScene');
        //await scene.scene.run('GameScene');
        scene.textLoad.setVisible(false);
        eventBus.emit('overlay:hide');
      } else {
        eventBus.emit('modal:chips', this.buyIn.min);
      }

      setTimeout(async () => {
        this.btnPlay.setVisible(true);
      }, 3000);

    }, 'play_hover', 'play', 'play_hover').setOrigin(0.5, 0.5).setScale(0.6)
      .setVisible(data.players.length < data.maxPlayers);

    /*
    this.btnView = new Button(scene, 240, -30, 'view', () => {
      eventBus.emit('table:view', {
        tid: data.tid,
      });
      scene.scene.switch('GameScene'); // решить с ид
    }, 'view_hover', 'view', 'view_hover').setOrigin(0.5, 0.5).setScale(0.6).setVisible(data.players.length > 1);
     */


    this.players = new LobbyPlayers(scene, 0, 0, data.maxPlayers);

    this.add([
      this.tableDesign,
      scene.add.image(0, -40, 'logoTable').setOrigin(0.5, 0.5),
      this.countPlayers,
      this.BuyIn,
      this.bet,
      this.btnPlay,
      //this.btnView,
      this.players,
    ]);

  }

  setCountPlayers(current, max) {
    this.countPlayers.text =this.translate['GAME']['PLAYERS'] + ' ' + current + '/' + max;
    this.btnPlay.setVisible(current < max)
  }

  tableSit() {

  }

  setBuyIn(min, max) {
    this.buyIn = {
      min: min,
      max: max,
    }
  }

  amountFormat(amount) {
    let result = amount;
    if (amount >= 1000) {
      result = (amount / 1000) + 'K'
    }

    if (amount >= 1000000) {
      result = (amount / 1000000) + 'M'
    }

    return result;
  }
}
