import {Scene} from 'phaser';
import {ninePlayers, fivePlayers} from '@/configGames';
import store from '@/store';
import PanelPlayer from '../panels/PanelPlayer';
import Table from '../class/table';
import TableMenuTop from '../menu/TableMenuTop';
import Player from '../class/Player';
import eventBus from '@/plugins/event-bus';
import Rules from '../panels/panelRules';
import Smiles from '../panels/panelSmile';
import Overlay from '../Modules/Overlay';
import axios from 'axios';
import {network} from '@/config';
import Button from "../class/button";

let players;
let playerOnTable = true;
let timeLastAction = Math.floor(Date.now() / 1000);
let showDownTimer = null;
const suitChar = {
  "c": "♣",
  "d": "♦",
  "h": "♥",
  "s": "♠",
};

export default class GameScene extends Scene {
  constructor() {
    super({key: 'GameScene'})
  }

  preload() {
    this.plugins.get('rexwebfontloaderplugin').addToScene(this);
    this.load.rexWebFont({
      custom: {
        families: ['electrolize'],
      }
    });

    this.soundFold = this.sound.add('soundFold', {
      mute: !this.game.config.sound,
      volume: 1,
      rate: 1,
      detune: 0,
      seek: 0,
      loop: false,
      delay: 0
    });

    this.soundAllin = this.sound.add('soundAllin', {
      mute: !this.game.config.sound,
      volume: 1,
      rate: 1,
      detune: 0,
      seek: 0,
      loop: false,
      delay: 0
    });

    this.soundWin = this.sound.add('soundWin', {
      mute: !this.game.config.sound,
      volume: 1,
      rate: 1,
      detune: 0,
      seek: 0,
      loop: false,
      delay: 0
    });

    this.soundShuffle = this.sound.add('soundShuffle', {
      mute: !this.game.config.sound,
      volume: 1,
      rate: 1,
      detune: 0,
      seek: 0,
      loop: false,
      delay: 0
    });


  }

  async wake() {
    this.account = await store.getters['account/account'];
    this.scene.run('GameScene');
    this.storeTable = await store.getters['games/table'];
    await this.refreshPlayers(this.storeTable);
    this.TableMenuTop.setBuyIn(this.storeTable.options.minBuyIn, this.storeTable.options.maxBuyIn);
    this.tableContainer.setBuyIn(this.storeTable.options.minBuyIn, this.storeTable.options.maxBuyIn);
    //console.log('wake gameScene', this.storeTable.tid);
    //this.scene.get('PreScene').soundDoor.play();

    playerOnTable = false;
    for (let i = 0; i < this.storeTable.players.length; i++) {
      if (this.storeTable.players[i]) {
        this.gamer[i].playerSmile.setVisible(false);
        if (this.storeTable.players[i].id === this.account.userId && this.TableMenuTop) {
          this.TableMenuTop.hideItem('btnSitDown');
          this.TableMenuTop.showItem('btnStandUp');
          this.userIdx = i;
          playerOnTable = true;
          break;
        }
      }
    }

    if (!playerOnTable) {
      if (this.TableMenuTop) {
        this.TableMenuTop.showItem('btnSitDown');
        this.TableMenuTop.hideItem('btnStandUp');
      }
    }

  }

  async initPlayers(maxPlayers) {
    let dealerPosition = 0;
    let smallBlindPosition = 1;
    let bigBlindPosition = 2;

    for (let i = 0; i < maxPlayers; i++) {
      this.gamer[i] = new Player(this, players.sits[i].x, players.sits[i].y, {
        index: i,
        name: 'init',
        stackSize: 0,
        maxPlayers: maxPlayers
      });

      if (i === smallBlindPosition) {
        this.sb = this.add.image(players.sits[i].dealer.x, players.sits[i].dealer.y, 'sb').setOrigin(0.5, 0.5);
        this.moveSB = this.tweens.add({
          targets: [this.sb],
          x: {value: players.sits[i].dealer.x},
          y: {value: players.sits[i].dealer.y},
          ease: 'Cubic.easeIn',
          duration: 1000,
          pause: true,
        });
      }

      if (i === bigBlindPosition) {
        this.bb = this.add.image(players.sits[i].dealer.x, players.sits[i].dealer.y, 'bb').setOrigin(0.5, 0.5);
        this.moveBB = this.tweens.add({
          targets: [this.bb],
          x: {value: players.sits[i].dealer.x},
          y: {value: players.sits[i].dealer.y},
          ease: 'Cubic.easeIn',
          duration: 1000,
          pause: true,
        });
      }

      if (i === dealerPosition) {
        this.gameDealer = this.add.sprite(players.sits[i].dealer.x, players.sits[i].dealer.y, 'dealer').setOrigin(0.5, 0.5);
        this.moveDealer = this.tweens.add({
          targets: [this.gameDealer],
          x: {value: players.sits[i].dealer.x},
          y: {value: players.sits[i].dealer.y},
          ease: 'Cubic.easeIn',
          duration: 1000,
          pause: true,
        });
      }

      //this.gamer[i].cards.hand.text = 'super' //обращаться по цепочке
    }
  }

  async destroyPlayers(storeTable) {
    for (let i = 0; i < storeTable.options.maxPlayers; i++) {
      if (!storeTable.players[i]) {
        this.gamer[i].setVisible(false);
        this.gamer[i].winPlayer.setVisible(false);
      } else {
        if (storeTable.players[i].left) {
          this.gamer[i].playerActionMsg.text = 'LEFT';
        }
      }
    }
  }

  async refreshPlayers(storeTable) {
    playerOnTable = false;
    this.playerPanel.hideActions();

    if (storeTable.table.dealerPosition) {
      this.bb.x = players.sits[storeTable.table.bigBlindPosition].dealer.x;
      this.bb.y = players.sits[storeTable.table.bigBlindPosition].dealer.y;

      this.sb.x = players.sits[storeTable.table.smallBlindPosition].dealer.x;
      this.sb.y = players.sits[storeTable.table.smallBlindPosition].dealer.y;

      this.gameDealer.x = players.sits[storeTable.table.dealerPosition].dealer.x;
      this.gameDealer.y = players.sits[storeTable.table.dealerPosition].dealer.y;
    }

    if (this.gamer[storeTable.table.currentPosition]) {
      this.gamer[storeTable.table.currentPosition].setActionMsg('');
    }
    this.tableContainer.setCommunityCards(storeTable.table.communityCards);
    if (storeTable.table.legalActions.length) {
      if (storeTable.players[storeTable.table.currentPosition].id === this.account.userId) {
        this.playerPanel.showActions(storeTable.table.legalActions);
      }
    }

    if (this.TableMenuTop) {
      //this.storeTable.table.currentRound === 'pre-flop' ? this.TableMenuTop.showItem('btnGetInGame') : this.TableMenuTop.hideItem('btnGetInGame');
    }


    if (!storeTable.table.currentRound) {
      storeTable.table.currentRound = 'WAIT PLAYERS';
    }

    if (storeTable.table.winners.length) {
      storeTable.table.currentRound = 'SHOWDOWN';
    }

    this.tableContainer.setRoundName(storeTable.table.currentRound.toUpperCase());

    for (let i = 0; i < storeTable.players.length; i++) {
      // skip players
      if (storeTable.players[i]) {
        if (!storeTable.players[i].left) {
          this.gamer[i].setVisible(true);
          this.gamer[i].setDataPlayer(storeTable.players[i]);
        } else {
          this.gamer[i].setVisible(false);
        }


        if (storeTable.table.activePlayers > 1) {
          /*
          if (storeTable.players[i].id === this.account.userId && storeTable.players[i].hand.name) {
            this.gamer[i].showCards();
          } else {
            this.gamer[i].hiddenCards.show();
          }
           */

          if (storeTable.players[i].id === this.account.userId) {
            if (this.TableMenuTop) {
              playerOnTable = true;
              this.TableMenuTop.hideItem('btnSitDown');
              this.TableMenuTop.showItem('btnStandUp');

              this.storeTable.table.currentRound === 'SHOWDOWN' ? this.TableMenuTop.showItem('btnGetInGame') : this.TableMenuTop.hideItem('btnGetInGame');

              if (this.storeTable.table.currentRound === 'pre-flop' && storeTable.players[i].stackSize < storeTable.options.minBuyIn) {
                this.TableMenuTop.showItem('btnGetInGame');

              } else {
                this.TableMenuTop.hideItem('btnGetInGame');
              }

              if (storeTable.players[i].left) {
                this.TableMenuTop.showItem('btnSitDown');
              }
            }
            //this.gamer[i].setPrivateData(storeTable.private); // добавил приватные данные
            this.gamer[i].showCards();
          } else {
            this.gamer[i].hideCards();
            this.gamer[i].hiddenCards.show();
          }

          if (storeTable.players[i].folded) {
            this.gamer[i].hideCards();
            this.gamer[i].hiddenCards.hide();
          }

        }


        if (storeTable.latestAction) {
          if (storeTable.players[i].id === storeTable.latestAction.userId) {
            this.gamer[i].setActionMsg(storeTable.latestAction.action.toUpperCase());
          }
        }

        if (storeTable.players[i].folded) {
          this.gamer[i].setActionMsg('FOLDED');
          this.gamer[i].hiddenCards.hide();
        }

        if (storeTable.table.currentPosition === i && !storeTable.players[i].folded) {
          this.gamer[i].timer.startTimer();
          this.gamer[i].current(true);
        } else {
          this.gamer[i].timer.stopTimer();
          this.gamer[i].current(false);
        }

      }
    }

    this.tableContainer.setBank(storeTable.table.potAmount);


    /** GAME OVER - WINNERS **/
    if (storeTable.table.winners.length) {
      storeTable.table.currentRound = '- SHOWDOWN -';
      this.TableMenuTop.showItem('btnGetInGame');
      this.soundWin.play();
      this.playerPanel.text = 'Game Over';
      this.playerPanel.hideActions();

      for (let i = 0; i < storeTable.players.length; i++) {
        if (storeTable.players[i]) {
          this.gamer[i].setActionMsg();
          if (storeTable.players[i].id === this.account.userId && storeTable.players[i].stackSize > storeTable.options.minBuyIn) {
            this.TableMenuTop.hideItem('btnGetInGame');
          }
        }
      }

      let communityCardsWinStr = '';
      for (let i = 0; i < storeTable.table.communityCards.length; i++) {
        communityCardsWinStr = communityCardsWinStr + storeTable.table.communityCards[i]._rank + suitChar[storeTable.table.communityCards[i]._suit] + ' ';
      }

      for (let i = 0; i < storeTable.players.length; i++) {
        if (storeTable.players[i]) {
          if (storeTable.players[i].showCards) {
            this.gamer[i].showCards();
          }


          for (let j = 0; j < storeTable.table.winners.length; j++) {
            if (storeTable.table.winners[j].id === storeTable.players[i].id) {
              this.gamer[i].setActionMsg('WIN');
              this.gamer[i].winPlayer.setVisible(true);

              let winnerCards = storeTable.players[i].holeCards[0]._rank + suitChar[storeTable.players[i].holeCards[0]._suit];
              winnerCards = winnerCards + ' ' + storeTable.players[i].holeCards[1]._rank + suitChar[storeTable.players[i].holeCards[1]._suit];

              eventBus.emit('chat-log:response', {
                name: storeTable.players[i].name,
                action: 'WIN ' + storeTable.players[i].hand.descr,
                cards: winnerCards + ' + ' + communityCardsWinStr,
                amount: 0, //storeTable.table.potAmount, //storeTable.table.winners[j].stackSize
              });
            }
          }
        }
      }

      clearTimeout(showDownTimer);
      showDownTimer = setTimeout(async () => {
        this.soundShuffle.play();
        this.tasovka.setVisible(true);
        this.tasovka.anims.play('walk');
        setTimeout(async () => {
          this.tasovka.anims.stop();
          this.tasovka.setVisible(false);
        }, 6250);

        for (let i = 0; i < storeTable.players.length; i++) {
          if (storeTable.players[i]) {
            this.gamer[i].hideCards();
            this.gamer[i].winPlayer.setVisible(false);
            this.gamer[i].setActionMsg();
            this.tableContainer.setCommunityCards([]); // hide community cards
            this.tableContainer.setBank(0); // hide bank
            this.tableContainer.setRoundName('.: Wait New Deal :.');

            // logout player & get in game
            if (storeTable.players[i].id === this.account.userId && storeTable.players[i].stackSize < storeTable.table.bigBlind) {
              const autoGet = store.getters['games/autoGet'];

              if (autoGet) {
                eventBus.emit('chips:getingame', {
                  min: storeTable.options.minBuyIn,
                  max: storeTable.options.maxBuyIn,
                });
              } else {
                eventBus.emit('modal:getingame', {
                  min: storeTable.options.minBuyIn,
                  max: storeTable.options.maxBuyIn,
                });
                setTimeout(async () => {
                  eventBus.emit('hide:getingame');
                }, 45000);
                setTimeout(async () => {
                  const timestamp = Math.floor(Date.now() / 1000);
                  const timeLine = Math.floor(timestamp - timeLastAction);
                  const storeTableDb = await store.getters['games/table'];
                  if (timeLine < 0 && storeTableDb.players[i]) {
                    if (storeTableDb.players[i].id === this.account.userId && storeTableDb.players[i].stackSize < storeTable.table.bigBlind) {
                      store.dispatch('games/tableLogout');
                      this.scene.switch('LobbyScene');
                    }
                  }
                }, 181000);
              }

            }
            // logout end

          }
        }

        if (this.moveSB.data) {
          if (this.moveSB.data[0].x !== players.sits[storeTable.table.smallBlindPosition].dealer.x) {
            this.moveSB = this.tweens.add({
              targets: [this.sb],
              x: {value: players.sits[storeTable.table.smallBlindPosition].dealer.x},
              y: {value: players.sits[storeTable.table.smallBlindPosition].dealer.y},
              ease: 'Cubic.easeIn',
              duration: 500,
            });
          }
        }

        if (this.moveBB.data) {
          if (this.moveBB.data[0].x !== players.sits[storeTable.table.bigBlindPosition].dealer.x) {
            this.moveBB = this.tweens.add({
              targets: [this.bb],
              x: {value: players.sits[storeTable.table.bigBlindPosition].dealer.x},
              y: {value: players.sits[storeTable.table.bigBlindPosition].dealer.y},
              ease: 'Cubic.easeIn',
              duration: 600,
            });
          }
        }

        if (this.moveDealer.data) {
          if (this.moveDealer.data[0].x !== players.sits[storeTable.table.dealerPosition].dealer.x) {
            this.moveDealer = this.tweens.add({
              targets: [this.gameDealer],
              x: {value: players.sits[storeTable.table.dealerPosition].dealer.x},
              y: {value: players.sits[storeTable.table.dealerPosition].dealer.y},
              ease: 'Cubic.easeIn',
              duration: 700,
            });
          }
        }


        this.TableMenuTop.hideItem('btnGetInGame');
      }, 5000)

    }

    await this.destroyPlayers(storeTable);
    if (!playerOnTable && this.TableMenuTop) {
      this.TableMenuTop.showItem('btnSitDown');
      this.TableMenuTop.hideItem('btnStandUp');
    }

  }

  async create() {
    this.soundChip = this.sound.add('soundChip', {
      mute: false,
      volume: 1,
      rate: 1,
      detune: 0,
      seek: 0,
      loop: false,
      delay: 0
    });
    this.gamer = [];
    let hiddenCardsFold;
    //this.storeTable = await store.dispatch('games/tableView', 0);
    this.storeTable = await store.getters['games/table'];
    //this.storeTable = (await axios.get(network.API + '/api/table-view/0')).data;
    this.account = await store.getters['account/account'];

    /** EVT **/
    let _self = this;
    eventBus.on('table:response', async (data) => {
      _self.storeTable = data.data;
      await _self.refreshPlayers(data.data);
      _self.TableMenuTop.setBuyIn(data.data.options.minBuyIn, data.data.options.maxBuyIn);
      _self.tableContainer.setBuyIn(data.data.options.minBuyIn, data.data.options.maxBuyIn);
      if (data.gameCounter) {
        _self.tableContainer.setGameCounter(data.gameCounter)
      }

      if (data.data.table.deckHash) {
        this.tableContainer.setDeckHash(data.data.table.deckHash)
      }
    }, this);

    eventBus.on('anim:getingame', async function (data) {
      _self.gamer[data.playerIdx].animGetInGame(data.amount)
    });

    players = this.storeTable.options.maxPlayers === 5 ? fivePlayers : ninePlayers;
    this.add.image(this.game.config.width / 2, this.game.config.height / 2, 'bgBlue').setOrigin(0.5, 0.5).setScale(1);
    this.tableContainer = new Table(this, this.game.config.width / 2, this.game.config.height / 2, 'blue');


    let currentRound = this.storeTable.table.currentRound;
    if (!this.storeTable.table.currentRound) {
      currentRound = 'WAIT PLAYERS';
    }
    this.tableContainer.setRoundName(currentRound.toUpperCase());
    this.tableContainer.setBank(this.storeTable.table.potAmount);
    this.tableContainer.setCommunityCards(this.storeTable.table.communityCards); // community cards
    this.tableContainer.setBuyIn(this.storeTable.options.minBuyIn, this.storeTable.options.maxBuyIn);
    this.tableContainer.setDeckHash(this.storeTable.table.deckHash);


    /** My cards **/
    /*
    this.tweens.add({
      targets: [card2],
      //alpha: { from: 0, to: 1 },
      //rotation: 6,
      angle: 12,
      x: '+=40',
      // alpha: { start: 0, to: 1 },
      // alpha: 1,
      // alpha: '+=1',
      ease: 'Sine.easeInOut',       // 'Cubic', 'Elastic', 'Bounce', 'Back'
      duration: 500,
      //repeat: -1,            // -1: infinity
      yoyo: false
    });
   */

    this.playerPanel = new PanelPlayer(this, 0, this.game.config.height);
    await this.initPlayers(this.storeTable.options.maxPlayers);
    await this.refreshPlayers(this.storeTable); // test only

    /** FOLD **/
    this.actionFold = async () => {
      this.soundFold.play();
      this.playerPanel.hideActions();
      timeLastAction = Math.floor(Date.now() / 1000);
      this.gamer[this.storeTable.table.currentPosition].hideCards();
      this.gamer[this.storeTable.table.currentPosition].hiddenCards.hide();
      hiddenCardsFold.setVisible(true);
      hiddenCardsFold.x = players.sits[this.storeTable.table.currentPosition].hiddenCards.x;
      hiddenCardsFold.y = players.sits[this.storeTable.table.currentPosition].hiddenCards.y;

      this.tweens.add({
        targets: [hiddenCardsFold],
        paused: false,
        duration: 600,  // function(target, targetKey, value, targetIndex, totalTargets, tween) { },
        ease: 'Linear',
        x: this.game.config.width / 2,        // start from current value
        y: 350,
        //rotation: 360,
        angle: 180,
        alpha: {from: 1, to: 0},
        yoyo: false,  // true to tween backward
        flipX: false,
        flipY: false,
        // delay to onComplete callback
        onComplete: async function () {
          hiddenCardsFold.setVisible(false);
        },
        offset: null,
        useFrames: false
      });


      setTimeout(async () => {
        this.gamer[this.storeTable.table.currentPosition].setActionMsg('FOLD');
        this.storeTable = await store.dispatch('games/playerAction', {
          action: 'fold',
          amount: 0,
          userId: this.account.userId,
          idx: this.storeTable.options.idx,
          pin: this.game.config.pin,
        });
        await this.refreshPlayers(this.storeTable);
      }, 300);


    };

    /** CHECK **/
    this.actionCheck = async () => {
      this.playerPanel.hideActions();
      timeLastAction = Math.floor(Date.now() / 1000);
      this.gamer[this.storeTable.table.currentPosition].setActionMsg('CHECK');
      this.storeTable = await store.dispatch('games/playerAction', {
        action: 'check',
        amount: 0,
        userId: this.account.userId,
        idx: this.storeTable.options.idx,
        pin: this.game.config.pin,
      });
      await this.refreshPlayers(this.storeTable);
    };

    /** CALL **/
    this.actionCall = async () => {
      this.playerPanel.hideActions();
      this.soundChip.play();
      timeLastAction = Math.floor(Date.now() / 1000);
      this.gamer[this.storeTable.table.currentPosition].setActionMsg('CALL');
      setTimeout(async () => {
        this.storeTable = await store.dispatch('games/playerAction', {
          action: 'call',
          amount: 0,
          userId: this.account.userId,
          idx: this.storeTable.options.idx,
          pin: this.game.config.pin,
        });
        await this.refreshPlayers(this.storeTable);
      }, 200);
    };

    /** RAISE **/
    this.actionRaise = async () => {
      this.playerPanel.hideActions();
      this.soundChip.play();
      timeLastAction = Math.floor(Date.now() / 1000);
      this.gamer[this.storeTable.table.currentPosition].setActionMsg('RAISE');
      const maxRise = Math.floor(this.storeTable.table.currentBet + this.storeTable.table.bigBlind);
      const amountRaise = this.storeTable.players[this.storeTable.table.currentPosition].stackSize >= maxRise ? maxRise : this.storeTable.players[this.storeTable.table.currentPosition].stackSize;
      this.storeTable = await store.dispatch('games/playerAction', {
        action: 'raise',
        amount: amountRaise,
        userId: this.account.userId,
        idx: this.storeTable.options.idx,
        pin: this.game.config.pin,
      });
      await this.refreshPlayers(this.storeTable);
    };

    /** BET **/
    this.actionBet = async () => {
      this.playerPanel.hideActions();
      this.soundChip.play();
      timeLastAction = Math.floor(Date.now() / 1000);
      const betAmount = this.storeTable.players[this.storeTable.table.currentPosition].stackSize >= this.storeTable.table.bigBlind ? this.storeTable.table.bigBlind : this.storeTable.players[this.storeTable.table.currentPosition].stackSize;
      this.gamer[this.storeTable.table.currentPosition].setActionMsg('BET ' + this.storeTable.table.bigBlind);
      this.storeTable = await store.dispatch('games/playerAction', {
        action: 'bet',
        amount: betAmount,
        userId: this.account.userId,
        idx: this.storeTable.options.idx,
        pin: this.game.config.pin,
      });
      await this.refreshPlayers(this.storeTable);
    };

    /** ALL-IN **/
    this.actionAllIn = async () => {
      this.playerPanel.hideActions();
      this.soundAllin.play();
      timeLastAction = Math.floor(Date.now() / 1000);
      this.gamer[this.storeTable.table.currentPosition].setActionMsg('ALL-IN');
      this.storeTable = await store.dispatch('games/playerAction', {
        action: 'allin',
        amount: 0,
        userId: this.account.userId,
        idx: this.storeTable.options.idx,
        pin: this.game.config.pin,
      });
      await this.refreshPlayers(this.storeTable);
    };

    this.TableMenuTop = new TableMenuTop(this, this.storeTable);

    this.btnTalk = new Button(this, 110, this.game.config.height - 220, 'btnTalk', async () => {
      eventBus.emit('chat:show');
    }, 'hover', 'default', 'hover').setScale(0.9);

    this.rules = new Rules(this, 2, 10);

    this.userIdx = -1;
    for (let i = 0; i < this.storeTable.players.length; i++) {
      if (this.storeTable.players[i]) {
        if (this.storeTable.players[i].id === this.account.userId) {
          this.userIdx = i;
          this.TableMenuTop.hideItem('btnSitDown');
          break;
        }
      }
    }
    this.smiles = new Smiles(this, this.game.config.width - 70, this.game.config.height - 910);
    this.btnRocket = new Button(this, this.game.config.width - 125, this.game.config.height - 220, 'btnRocket', async () => {
      //this.smiles.tweenSmiles.resume();
      this.smiles.tweenSmiles.play();
      this.smiles.setVisible(!this.smiles.visible);
    }, 'hover', 'default', 'hover').setScale(0.9);
    eventBus.on('smile:set', async function (frame) {
      if (_self.userIdx > -1) {
        //_self.gamer[userIdx].setSmile(frame);
        eventBus.emit('send:smile', {
          op: 'show:smile',
          tid: _self.storeTable.tid,
          idx: _self.userIdx,
          frame: frame,
          userId: _self.account.userId,
        });
      }
    });

    eventBus.on('show:smile', async function (data) {
      if (_self.gamer[data.idx]) {
        _self.gamer[data.idx].setSmile(data.frame);
        _self.gamer[data.idx].tweenSmile.play();
      }
    });

    hiddenCardsFold = this.add.image(this.game.config.width / 2, this.game.config.height / 2, 'hiddenCards').setOrigin(0.5, 0.5).setScale(0.55).setVisible(false);

    this.cameras.main.fadeIn(1000);

    this.overlay = new Overlay(this);

    timeLastAction = Math.floor(Date.now() / 1000);


    this.tasovka = this.add.sprite(this.game.config.width / 2,this.game.config.height / 2 - 35, 'tasovka').setScale(0.9).setVisible(false);
    let frameNames = this.anims.generateFrameNumbers('tasovka', {
      start: 0, end: 33,
      prefix: '', suffix: ''
    });
    this.anims.create({ key: 'walk', frames: frameNames, frameRate: 30, repeat: -1 });


    this.sys.events.on('wake', this.wake, this);
  }


  update() {

  }
}
