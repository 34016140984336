import Button from '../class/button';
import ButtonText from '../class/buttonText';
import PopupExitGame from './PopupExitGame';
import store from '@/store';
import eventBus from '@/plugins/event-bus';

export default class TableMenuTop extends Phaser.GameObjects.Container {
  constructor(scene, data = {}) {
    super(scene);
    scene.add.existing(this);
    //this.setVisible(false);

    this.buyIn = {
      min: data.options.minBuyIn,
      max: data.options.maxBuyIn,
    };

    const account = store.getters['account/account'];

    this.menuRightBg = scene.add.image(70, 0, 'bgMenuRight').setOrigin(0, 1).setInteractive();
    this.menuRightBg.on('pointerdown', function () {
      scene.tweens.add({
        targets: [this.menuContainer],
        y: {value: 0},
        ease: 'Cubic.easeIn',
        duration: 200,
      })
    }, this);

    this.btnContinue = new ButtonText(scene, scene.game.config.width - 250, -770, 'menuItm', () => {
      scene.tweens.add({
        targets: [this.menuContainer],
        y: {value: 0},
        ease: 'Cubic.easeIn',
        duration: 200,
      })
    }, 'menuItmHover.png', 'menuItm.png', 'menuItmHover.png', {
      text: scene.game.config[scene.game.config.locale]['APP']['CONTINUE'],
      color: '#00e7ff',
      size: 32
    });

    this.btnSitDown = new ButtonText(scene, scene.game.config.width - 250, -770 + 100, 'menuItm', () => {
      this.menuContainer.y = 0;

      const player = store.getters['account/player'];
      let avgBuyIn = Math.floor((this.buyIn.min + this.buyIn.max) / 2);
      if (player.chips.real < avgBuyIn) {
        avgBuyIn = this.buyIn.min;
      }
      if (player.chips.real >= avgBuyIn) {
        store.dispatch('games/tableSit', {
          tid: data.tid,
          idx: data.options.idx,
          userId: account.userId,
          stack: avgBuyIn,
          pin: scene.game.config.pin,
        });
        this.btnStandUp.setVisible(true);
        this.btnSitDown.setVisible(false);
      } else {
        eventBus.emit('modal:chips', this.buyIn.min);
      }
    }, 'menuItmHover.png', 'menuItm.png', 'menuItmHover.png', {
      text: scene.game.config[scene.game.config.locale]['GAME']['SIT_DOWN'],
      color: '#00e7ff',
      size: 32
    }).setVisible(false);

    this.btnStandUp = new ButtonText(scene, scene.game.config.width - 250, -770 + 100, 'menuItm', () => {
      this.menuContainer.y = 0;
      store.dispatch('games/tableLogout');
      this.btnStandUp.setVisible(false);
      this.btnSitDown.setVisible(true);
    }, 'menuItmHover.png', 'menuItm.png', 'menuItmHover.png', {
      text: scene.game.config[scene.game.config.locale]['GAME']['STAND_UP'],
      color: '#00e7ff',
      size: 32
    }).setVisible(!this.btnSitDown.visible);



    this.btnLeave = new ButtonText(scene, scene.game.config.width - 250, -770 + 200, 'menuItm', () => {
      this.menuContainer.y = 0;
      this.popupExit.setVisible(true);
      this.btnStandUp.setVisible(true);
      this.btnSitDown.setVisible(true);
    }, 'menuItmHover.png', 'menuItm.png', 'menuItmHover.png', {
      text: scene.game.config[scene.game.config.locale]['GAME']['LEAVE_GAME'],
      color: '#00e7ff',
      size: 32
    });

    this.btnGetInGame = new ButtonText(scene, scene.game.config.width - 250, -770 + 300, 'menuItm', () => {
      this.menuContainer.y = 0;
      eventBus.emit('modal:getingame', this.buyIn);
    }, 'menuItmHover.png', 'menuItm.png', 'menuItmHover.png', {
      text: scene.game.config[scene.game.config.locale]['GAME']['GET_IN_GAME'],
      color: '#00e7ff',
      size: 32
    }).setVisible(false);

    this.menuContainer = scene.add.container(0,0, [
      this.menuRightBg,
      this.btnContinue,
      this.btnStandUp,
      this.btnSitDown,
      this.btnLeave,
      this.btnGetInGame,
    ]);

    this.add([
      new Button(scene, scene.game.config.width - 160, 25, 'btnNormal', () => {
        scene.tweens.add({
          targets: [this.menuContainer],
          y: {value: 829},
          ease: 'Cubic.easeIn',
          duration: 450,
        })
      }, 'btn_press.png', 'btn_normal.png', 'btn_press.png')
        .setScale(0.75),
      scene.add.text(scene.game.config.width - 160, 30, scene.game.config[scene.game.config.locale]['GAME']['MENU'], {
        fontFamily: 'electrolize',
        fontSize: 30,
        color: '#00f7ff'
      }).setOrigin(0.5, 0.5)
    ]);


    eventBus.on('table:response', async (data) => {
      if (data.players) {
        for (let i = 0; i < data.players.length; i++) {
          if (data.players[i]) {
            if (data.players[i].id === account.userId) {

              break;
            }
          }
        }
      }
    });

    this.popupExit = new PopupExitGame(scene, scene.game.config.width / 2, scene.game.config.height / 2);

  }

  setBuyIn(min, max) {
    this.buyIn = {
      min: min,
      max: max,
    }
  }

  hideItem(item) {
    if (item === 'btnGetInGame') {
      this.btnGetInGame.setVisible(false);
    }

    if (item === 'btnStandUp') {
      this.btnStandUp.setVisible(false);
      this.btnSitDown.setVisible(true);
    }

    if (item === 'btnSitDown') {
      this.btnSitDown.setVisible(false);
    }

  }

  showItem(item) {
    if (item === 'btnGetInGame') {
      this.btnGetInGame.setVisible(true);
    }

    if (item === 'btnStandUp') {
      this.btnStandUp.setVisible(true);
    }

    if (item === 'btnSitDown') {
      this.btnSitDown.setVisible(true);
    }
  }

}
