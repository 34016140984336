import eventBus from '@/plugins/event-bus';

export default class OctaBalance extends Phaser.GameObjects.Container {
  constructor(scene, x, y, options = {
    icon: 'chipGold1',
    scale: 1,
    x: -225,
    balance: 0,
    type: 'sth',
    color: '#86a1ab'
  }) {
    super(scene, x, y);
    scene.add.existing(this);

    this.octagon = scene.add.polygon(0, 0, [20, 0, 300, 0, 320, 15, 320, 50, 300, 65, 20, 65, 0, 50, 0, 15], 0x000000).setAlpha(0.5);
    this.octagon.setStrokeStyle(2, 0x1d3f62);
    this.icon = scene.add.image(options.x, 0, options.icon).setOrigin(0.5, 0.5).setScale(options.scale).setInteractive();
    this.balance = scene.add.text(0, 0, options.balance, {
      fontFamily: 'electrolize',
      fontSize: 32,
      fill: options.color
    }).setOrigin(0.5, 0.5);
    this.textPlus = scene.add.text(110, 0, '+', {
      fontFamily: 'electrolize',
      fontSize: 64,
      fill: options.color, //'#6ac1e6'
    }).setOrigin(0, 0.5).setInteractive();

    this.add([
      this.octagon,
      this.icon,
      this.balance,
      this.textPlus,
    ]);

    this.icon.on('pointerdown', async function () {
      if (options.type === 'sth') {
        eventBus.emit('modal:wallet');
      }

      if (options.type === 'chips') {
        scene.scene.switch('ChipsScene');
      }
    }, this);

    this.textPlus.on('pointerdown', async function () {
      if (options.type === 'sth') {
        eventBus.emit('modal:wallet');
      }

      if (options.type === 'chips') {
        scene.scene.switch('ChipsScene');
      }
    }, this);


    scene.tweens.add({
      targets: [this.icon],
      angle: 360,
      ease: 'Sine.easeInOut',
      duration: 1000,
      yoyo: true
    });

  }

  setBalance(amount) {
    this.balance.text = amount;
  }
}
