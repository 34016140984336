export default class PlayerCards extends Phaser.GameObjects.Container {
  constructor(scene, x, y) {
    super(scene, x, y);
    scene.add.existing(this);
    this.setVisible(false);

    const scaleCards = 0.72;
    this.one = scene.add.image(-20, 0, 'cards', 'back-red')
      .setOrigin(0.5, 0.5).setScale(scaleCards).setAngle(-5); // prev scale 0.38
    this.two = scene.add.image(20, 0, 'cards', 'back-red')
      .setOrigin(0.5, 0.5).setScale(scaleCards).setAngle(10);
    this.hand = scene.add.text(0, -82, 'hand', {
      fontFamily: 'electrolize',
      fontSize: 22,
      color: '#FFFFFF'
    }).setOrigin(0.5, 0.5);


    this.add([
      this.one,
      this.two,
      this.hand
    ]);
  }

  set(cards, hand) {
    if (hand.name) {
      this.one.setFrame(cards[0]._rank + cards[0]._suit);
      this.two.setFrame(cards[1]._rank + cards[1]._suit);
      this.hand.text = hand.name;
    } else {
      this.setVisible(false);
    }
  }

  show() {
    this.setVisible(true);
  }

  hide() {
    this.setVisible(false);
  }
}
