import LobbyTable from '../../class/LobbyTable';

export default class TablesContainer extends Phaser.GameObjects.Container {
  constructor(scene, x, y, lobbyData = []) {
    super(scene, x, y);
    scene.add.existing(this);
    for (let i=0; i < lobbyData.length; i++) {
      this.add(new LobbyTable(scene, i * 1220, 0, lobbyData[i]).setScale(0.9));
    }
  }
}
