import Button from '../../class/button';
import store from '@/store';

export default class LobbyBotPanel extends Phaser.GameObjects.Container {
  constructor(scene, x, y) {
    super(scene, x, y);
    scene.add.existing(this);

    //this.panel = scene.add.image(0, 0, 'menuTop').setOrigin(0.5, 0);

    this.btnCategory = new Button(scene, 890, 60, 'btnCategory', async () => {
      const lobbyStyle = await store.dispatch('games/changeLobbyStyle');
    }, 'hover', 'default', 'hover').setOrigin(0.5, 0.5).setScale(0.8);

    this.add([
      //this.panel,
      this.btnCategory
    ]);
  }
}
