import LobbyPlayer from '../../class/LobbyPlayer';

export default class LobbyPlayers extends Phaser.GameObjects.Container {
  constructor(scene, x, y, maxPlayers) {
    super(scene, x, y);
    scene.add.existing(this);


    const coords5 = [
      {x: -250, y: -300},
      {x: 250, y: -300},
      {x: 510, y: 0},
      {x: 0, y: 240},
      {x: -510, y: 0},
    ];

    const coords9 = [
      {x: -240, y: -300},
      {x: 240, y: -300},
      {x: 510, y: -140},
      {x: 530, y: 140},
      {x: 290, y: 250},
      {x: 0, y: 280},
      {x: -290, y: 250},
      {x: -530, y: 140},
      {x: -510, y: -140},
    ];

    let coords = coords5;
    if (maxPlayers > 5) {
      coords = coords9;
    }

    //this.players = [];
    for (let i = 0; i < coords.length; i++) {
      this.add(new LobbyPlayer(scene, coords[i].x, coords[i].y).setScale(0.85));
      //this.players.push(new LobbyPlayer(scene, coords[i].x, coords[i].y).setScale(0.85));
    }

    //this.add(this.players);

  }
}
