import Phaser from 'phaser';
import BootScene from './scenes/BootScene';
import PreScene from './scenes/PreScene';
import CreditsScene from './scenes/CreditsScene';

import LoginScene from './scenes/LoginScene';

import GameMenu from './scenes/crypto/GameMenu';
import ChipsScene from './scenes/ChipsScene';
import LobbyScene from './scenes/crypto/LobbyScene';
import GameScene from './scenes/crypto/GameScene';
import ratio from 'aspect-ratio';
import WebFontLoaderPlugin from 'phaser3-rex-plugins/plugins/webfontloader-plugin.js';

const screenRatio = ratio(window.screen.width, window.screen.height)

function launch(containerId) {
  return new Phaser.Game({
    url: "https://smartholdem.io",
    title: "Card Engine SmartHoldem",
    version: "1.0.0",
    banner: true,
    //type: Phaser.AUTO,
    type: Phaser.WEBGL,
    width: 1920,
    height: screenRatio === '16:9' ? 1080 : 1200,
    //backgroundColor: '#0b1f38',
    transparent: true,
    roundPixels: false,
    antialias: true,
    stage: {
      smoothed: true,
    },
    scale: {
      mode: Phaser.Scale.HEIGHT_CONTROLS_WIDTH, //Phaser.Scale.HEIGHT_CONTROLS_WIDTH //Phaser.Scale.WIDTH_CONTROLS_HEIGHT
      autoCenter: Phaser.Scale.CENTER_BOTH,
    },
    parent: containerId,
    plugins: {
      global: [{
        key: 'rexwebfontloaderplugin',
        plugin: WebFontLoaderPlugin,
        start: true
      },
      ]
    },
    scene: [
      BootScene,
      LoginScene,
      PreScene,
      CreditsScene,
      GameMenu, LobbyScene, ChipsScene, GameScene,
    ],
  })
}

export default launch
export {launch}
