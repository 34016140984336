import Button from '../class/button';

export default class ButtonPlayAction extends Phaser.GameObjects.Container {
  constructor(scene, x, y, texture, actionOnClick = () => {
  }, overFrame, outFrame, downFrame, text) {
    super(scene);
    scene.add.existing(this);

    this.btn = new Button(scene, x, y, texture, actionOnClick, overFrame, outFrame, downFrame).setOrigin(0.5, 0.5).setScale(0.5);
    this.amount = scene.add.text(x, y, text, {
      fontFamily: 'electrolize',
      fontSize: 22,
      fill: '#00e7ff'
    }).setOrigin(0.5, 0.5);

    this.add(
      [
        this.btn,
        this.amount
      ]
    )

  }

  setAmount(text) {
    this.amount = text;
  }

}


